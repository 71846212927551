<template>
  <div class="bg-white" v-loading="loading">
    <div class="row">
      <div :class="modifiable ? 'col-12' : 'col-8 offset-2'">
        <div class="d-flex justify-content-between">
          <p class="fs-20 fw-500" v-if="!modifiable">{{ form.name }}</p>
          <div
            class="cursor-pointer"
            v-if="prop_indication_order && !editing"
            @click="editing = true"
          >
            <i class="el-icon-edit fs-24"></i>
          </div>
        </div>
      </div>
      <div class="col-6" v-show="modifiable">
        <div class="box-shadow p-2">
          <div
            class="fs-18 fw-500 txt-grey-900 mb-3 max-line-1"
          >{{ $t("schedule_page.lbl_add_indicator") }}</div>
          <div class="flex flex-col md:flex-row gap-3 items-center">
            <el-input
              :placeholder="$t('schedule_page.lbl_search_sh')"
              suffix-icon="el-icon-search"
              v-model="search"
              :clearable="true"
              @keyup.enter.native="onSearch(search)"
            ></el-input>
            <el-button
              v-if="hasExaminationEcom"
              @click="handleOpenModalSelectServicePublic"
              type="primary"
              size="medium"
            >{{ $t("Dịch vụ TMĐT") }}</el-button>
          </div>
          <div v-if="!type" class="flex space-x-3 my-3 select-class pb-1">
            <div
              class="cursor-pointer px-3 py-2 hover:bg-indigo-300 text-black rounded-lg class-item"
              :class="!indicationClassParentId ? 'bg-indigo-200' : ''"
              :key="0 + 'level1'"
              @click="selectIndicationClasses(0)"
            >{{ $t("schedule_page.lbl_all") }}</div>
            <div
              class="cursor-pointer px-3 py-2 hover:bg-indigo-300 text-black rounded-lg class-item"
              :class="
                indicationClassParentId === item.id
                  ? 'bg-indigo-200'
                  : 'bg-light'
              "
              v-for="item in indicationClasses"
              :key="item.id + 'indicationClasses'"
              @click="selectIndicationClasses(item.id)"
            >{{ item.name }}</div>
          </div>
          <div class="h-tree select-items pt-2">
            <div
              v-infinite-scroll="handleNexPageCategories"
              :infinite-scroll-disabled="disabledScroll"
            >
              <el-tree
                v-loading="indication_class_loading"
                class="filter-tree"
                :data="handledIndicationCategories"
                check-on-click-node
                @check-change="handleCheckChange"
                show-checkbox
                ref="tree"
                node-key="id"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span class="fs-16 txt-grey-900 mb-0">
                    {{
                    getIndicationName(data) || node.disabled
                    }}
                  </span>
                  <!-- <br />
                  <span class="fs-13 txt-pri" v-html="getCategoryLabel(data.label)"></span>-->
                </span>
              </el-tree>
              <p
                class="text-center"
                v-if="indication_categories_loading"
              >{{ $t("schedule_page.lbl_loading") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div :class="modifiable ? 'col-6 relative' : 'col-8 offset-2 relative'">
        <el-input
          placeholder="Tên phiếu chỉ định"
          v-model="form.name"
          :clearable="true"
          v-if="modifiable"
        ></el-input>
        <!-- <div
          class="fs-18 fw-500 txt-grey-900 mb-3 flex items-center justify-between mt-2"
        >
          <span>{{ $t("schedule_page.lbl_indication_list") }}</span>
          <div
            v-if="indicationServices && indicationServices.length"
            class="flex gap-2"
          >
            <div
              class="ml-auto px-3 py-2 rounded-lg border border-primary flex cursor-pointer btn-add"
              @click="handleOpenModalAddReceipt(show)"
            >
              <img
                src="@public/assets/images/icon/patientVisit/plus-circle.svg"
                alt
              />
              <p class="text-primary mb-0 ml-2 text-center text-sm">
                Tạo phiếu thu
              </p>
            </div>
            <div
              @click="handleOpenListReceipt"
              class="ml-auto px-3 py-2 rounded-lg border border-primary flex cursor-pointer btn-add"
            >
              <p class="text-primary mb-0 text-center text-sm">DS phiếu thu</p>
            </div>
          </div>
        </div>-->
        <div class="selected-indication mt-2">
          <div
            class="selected-indication-item"
            :class="modifiable ? 'indication-item-hover' : ''"
            v-for="(indication, index) in selected"
            :key="'idct' + index"
          >
            <div v-if="indication" class="flex align-items-center justify-content-between">
              <div class="w-100 p-1 flex justify-between items-center gap-1">
                <p class="fs-16 fw-500 txt-grey-900 mb-0 flex-1">{{ getIndicationName(indication) }}</p>
                <el-input-number class="cs-input" v-model="indication.indicationAmount" :min="1"></el-input-number>
                <!-- <div class="row" :class="!modifiable ? 'disable-click' : ''">
                  <div class="col-6">
                    <div class="dropdown">
                      <div
                        class="cursor-pointer el-icon-map-location d-flex h-40px align-items-center"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="max-line-1 ml-1 fs-14 txt-grey-600">
                          {{
                            indication.target_name
                              ? indication.target_name
                              : modifiable
                              ? $t("schedule_page.lbl_execution")
                              : $t("schedule_page.lbl_no_exe_place")
                          }}
                        </span>
                      </div>
                      <div
                        class="dropdown-menu h-30 overflow-y-auto"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          v-for="target in demo"
                          :key="target.id + 'target-item'"
                          @click="setNameTargetIndication(indication, target)"
                          >{{ target.name }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="cursor-pointer fs-14 txt-grey-600">
                      <el-date-picker
                        prefix-icon="el-icon-date"
                        v-model="indication.due_date"
                        type="date"
                        :placeholder="
                          modifiable
                            ? $t('schedule_page.lbl_due_date')
                            : $t('schedule_page.lbl_no_due_date')
                        "
                        class="input-deal-date"
                        :clearable="false"
                      ></el-date-picker>
                    </div>
                  </div>
                </div>-->
              </div>
              <div class="delete-indication-wraper text-right">
                <span
                  role="button"
                  @click="handleDeleteIndication(indication)"
                  class="delete-indication"
                >
                  <i class="el-icon-close fs-22"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-end mt-2" v-if="modifiable">
          <button
            class="btn btn-light mr-2"
            @click="handlerCancel"
          >{{ $t("schedule_page.btn_cancel") }}</button>
          <button :disabled="isDisabledCreate" class="btn bg-pri text-white" @click="onSubmit()">
            {{
            $t(
            prop_indication_order && prop_indication_order.id
            ? "Cập nhật"
            : "Tạo phiếu"
            )
            }}
          </button>
        </div>
        <div v-else-if="prop_indication_order" class="d-flex justify-content-end mt-2">
          <button
            class="btn bg-pri text-white"
            @click="goToPrint"
          >{{ $t("schedule_page.btn_print") }}</button>
        </div>
      </div>
    </div>
    <!-- <ModalAddReceiptForm
      v-if="indicationServices && indicationServices.length"
      :indicationResponse="indicationResponse"
      @cancel="handleOpenModalAddReceipt('')"
      @onRefresh="handleRefresh"
    ></ModalAddReceiptForm>
    <ModalIndicationReceipt
      ref="ModalIndicationReceipt"
      :ctaTrigger="ctaTrigger"
      :indicationResponse="indicationResponse"
      @onClose="handleCloseModalIndicationReceipt"
    />-->
    <ModalSelectServicePublic
      :selectedServiceProp="selectedEcomService"
      ref="ModalSelectServicePublic"
      @onSelected="handleSelectServiceEcom"
    />
    <ModalConfirmConcent ref="ModalConfirmConcent" @onSuccess="handleOpenModalEnterOTP" />
    <ModalEnterOTP ref="ModalEnterOTP" @onSuccess="onSubmit" />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import dataDemo from '../../../pages/ElectronicMedicalRecords/components/Indications/ModalIndication/dataMemo'
import { EXAMINATION_STATUS } from '../../../utils/constants'
import ModalIndicationReceipt from './ModalIndicationReceipt.vue'
import ModalAddReceiptForm from './modal/ModalAddReceiptForm.vue'
import ModalSelectServicePublic from './modal/ModalSelectServicePublic.vue'
import { cloneDeep } from 'lodash'
import ModalConfirmConcent from './modal/ModalConfirmConcent.vue'
import ModalEnterOTP from './modal/ModalEnterOTP.vue'
import { mixinHasFeatures } from '@/utils/mixinHasFeatures'

export default {
  name: 'CUInficationOrderV2',
  mixins: [mixinHasFeatures],

  components: {
    ModalIndicationReceipt,
    ModalAddReceiptForm,
    ModalSelectServicePublic,
    ModalConfirmConcent,
    ModalEnterOTP
  },
  props: {
    patientVisit: {
      type: Object
    },
    patient_visit_id: {
      type: Number,
      required: true
    },
    mr_id: {
      type: Number
    },
    hr_id: {
      type: Number
    },
    type: {
      type: Number
    },
    prop_indication_order: {
      type: Object
    }
  },
  data () {
    return {
      modify: false,
      form: {
        name: ''
      },
      selected: [],
      deleted: [],
      search: '',
      indicationClasses: [],
      indicationClassParentId: null,
      indication_class_loading: false,
      indication_categories_loading: false,
      demo: dataDemo,
      moment,
      editing: false,
      loading: false,
      indication_order: null,
      indicationCategoriesPaging: {},
      count: 0,
      show: 'test',
      indicationResponse: {},
      indicationServices: [],
      ctaTrigger: 0,
      isDisabledCreate: false,
      stepConfirm: 0,
      selectedEcomService: []
    }
  },
  async created () {
    if (this.type === 4) {
      this.getListSupplier()
    }
    this.setDefaultName()
    await this.getIndicationClasses()
    await this.prepare()
  },
  watch: {
    'selected.length' (length, old) {
      if (length && old) {
        this.handleSetCheckedKeys()
      }
    },
    patientVisit (data) {
      console.log(data)
    }
  },
  computed: {
    handledIndicationCategories () {
      if (!this.indicationServices) return []
      let result = this.indicationServices.map(ig => {
        // ig.level = 1
        // // ig.label = ig.name
        // let foundHasResult = this.selected.find((I) => {
        //   return I.id === ig?.id && .indication_order_id
        // })
        // ig.disabled = !!foundHasResult
        return ig
      })
      return result
    },
    modifiable () {
      if (this.prop_indication_order) {
        return (this.editing = true)
      }
      return !this.prop_indication_order || !!this.editing
    },
    noMore () {
      return (
        !this.indicationCategoriesPaging?.total_page ||
        this.indicationCategoriesPaging.current_page >=
          this.indicationCategoriesPaging.total_page
      )
    },
    disabledScroll () {
      return (
        this.indication_class_loading ||
        this.indication_categories_loading ||
        this.noMore
      )
    }
    // selectedEcomService () {
    //   return this.selected
    //     ?.filter((item) => item.isEcom)
    //     ?.map((item) => {
    //       delete item?.indicationAmount
    //       return item
    //     })
    // }
  },
  async mounted () {},
  methods: {
    async getListSupplier () {
      const params = {
        limit: 1000,
        page: 1
      }
      this.indication_class_loading = true
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getListSupplier(params, this.$clinicId)
      this.demo = res.data.data.map(x => ({
        id: x.supplier_id,
        name: x.supplier_name,
        org_id: x.provider_id
      }))
      this.indication_class_loading = false
    },
    ...mapActions('appointmentStore', ['updatePatientVisitByIdRequest']),
    async prepare () {
      // this.indicationServices = this.prop_indication_order?.indication || []
      const [loadedIOrderDetail, loadedICategories] = await Promise.all([
        this.getIndicationOderDetail(this.prop_indication_order?.id || null),
        this.getIndicationsCategories()
      ])
      if (loadedIOrderDetail && loadedICategories) {
        this.handlerIndicationsOrder()
      }
    },
    onSearch (search) {
      this.search = search
      this.getIndicationsCategories()
    },
    async getIndicationClasses () {
      const params = {
        level: 1,
        all: true
      }
      this.indication_class_loading = true
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getIndicationClasses(params)
      this.indicationClasses = res?.data
      this.indication_class_loading = false
    },
    handleNexPageCategories () {
      if (this.noMore) return
      this.getIndicationsCategories(true)
    },
    async getIndicationsCategories (isNext = false) {
      if (!this.$globalClinic?.id) return
      if (!isNext) this.indicationServices = []
      const params = {
        keyword: this.search,
        page_num:
          isNext &&
          this.indicationCategoriesPaging.current_page <
            this.indicationCategoriesPaging.total_page
            ? this.indicationCategoriesPaging.current_page + 1
            : 1,
        group_id: this.type,
        page_size: 1000,
        ws_id: this.$globalClinic?.id
      }

      if (!params.level1) delete params.level1
      if (!params.search) delete params.search
      this.indication_categories_loading = true
      try {
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getLocalService(params)

        const { data: results, page } = res.data
        const newData = results?.map(item => ({
          ...item,
          local_service_id: item?.service_locals?.id
        }))

        this.indicationServices = isNext
          ? [...this.indicationServices, ...newData]
          : newData
        this.indicationCategoriesPaging = {
          ...page,
          current_page: page?.page_num || 1
        }
        this.indication_categories_loading = false
        return true
      } catch (error) {
        console.log(error)
        this.indication_categories_loading = false
        return false
      }
    },
    selectIndicationClasses (classId) {
      if (classId) {
        this.indicationClassParentId = classId
      } else {
        this.indicationClassParentId = null
      }
      this.getIndicationsCategories()
    },
    async getIndicationOderDetail (id) {
      if (!id) {
        this.indication_order = null
        return
      }
      this.loading = true
      const loaded = await this.$rf
        .getRequest('DoctorRequest')
        .getIndicationDetailV2(id)
        .then(r => {
          this.indication_order = r.data
          this.indicationResponse = r.data
          return true
        })
        .finally(() => {
          this.loading = false
          return false
        })
      return loaded
    },
    setNameTargetIndication (item, t) {
      this.$set(item, 'org_id', t.org_id)
      this.$set(item, 'target_name', t.name)
      this.$set(item, 'target_id', t.id)
    },
    async ApptStatus (value) {
      const params = {
        status: value
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .ApptStatus(this.appointment.id, params)
      this.getAppts()
    },
    baseDataUpdate (record) {
      return {
        appt_id: record.appt_id,
        clinic_id: record.clinic_id,
        doctor_id: record.doctor.id,
        cr_id: record.cr_id,
        person_id: record.person_id,
        start_time: window
          .moment(record.start_time)
          .utc()
          .subtract(7, 'Hours'),
        end_time: record.end_time,
        checkin_time: record.checkin_time,
        cancel_reason: ''
      }
    },
    async baseHandle ({ record, status }) {
      const self = this
      if (!record || !status) {
        self.$message({
          type: 'error',
          message: self.$t(`Không tìm thấy thông tin bệnh nhân`)
        })
        return
      }
      const data = self.baseDataUpdate(record)
      await this.updatePatientVisitByIdRequest({
        id: record.id,
        data: {
          ...data,
          status: status
        },
        isDetail: false
      })
    },
    async updateIndicationOrder () {
      if (!this.prop_indication_order) return

      const r = await this.$confirm(
        'Bạn có chắc chắn muốn cập nhật Chỉ định không?',
        'Xác nhận tạo Chỉ định',
        {
          dangerouslyUseHTMLString: true,
          cancelButtonText: this.$t('lbl_cancel'),
          confirmButtonText: this.$t('lbl_confirm')
        }
      ).catch(() => {})

      if (!r) return

      // const cateIds = this.selected.map((item) => {
      //   item.remove = false
      //   delete item.target_name
      //   return item
      // })
      // const reomovedIds = this.deleted.map((item) => {
      //   item.remove = true
      //   return item
      // })
      // const indications = [...cateIds, ...reomovedIds]
      // const indications = [...cateIds]

      const params = {
        cate: this.type,
        patient_visit_id: this.patient_visit_id,
        name: this.form.name,
        indications: this.selected
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .putIndicationOder(this.prop_indication_order.id, params)
        .then(res => {
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })

          if (this.prop_indication_order) {
            this.$emit('refresh', {
              reCall: true
            })
          } else {
            this.$emit('refresh', false)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async onSubmit (hasChecked, otpData) {
      if (!this.form.name) {
        this.$message({
          type: 'error',
          message: this.$t(`Vui lòng nhập tên phiếu chỉ định.`)
        })
        return
      }
      if (!this.selected || !this.selected.length) {
        this.$message({
          type: 'error',
          message: this.$t(`Vui lòng chọn chỉ định.`)
        })
        return
      }

      const hasEcomIndication = this.selected?.some(
        item => item?.type_service === 1
      )

      if (hasEcomIndication && !hasChecked) {
        // this.stepConfirm = 1
        this.$refs.ModalConfirmConcent.handleOpen(this.patientVisit)
        return
      }

      if (this.prop_indication_order) {
        this.updateIndicationOrder()
        return
      }
      // if (this.type === 4 && this.selected.find((x) => x.target_name === '')) {
      //   this.$message({
      //     type: 'error',
      //     message: this.$t(`Vui lòng điền thông tin nơi thực hiện`)
      //   })
      //   return
      // }
      if (!hasChecked) {
        const r = await this.$confirm(
          'Bạn có chắc chắn muốn tạo chỉ định không?',
          'Xác nhận tạo Chỉ định',
          {
            dangerouslyUseHTMLString: true,
            cancelButtonText: this.$t('lbl_cancel'),
            confirmButtonText: this.$t('lbl_confirm')
          }
        ).catch(() => {})

        if (!r) return
      }

      try {
        this.loading = true
        const cateIds = this.selected.map(item => {
          return { ...item, qty: item?.indicationAmount || 1 }
        })
        const params = {
          cate_id: this.type,
          patient_visit_id: this.patient_visit_id,
          name: this.form.name,
          indications: cateIds,
          user_id: otpData?.user_id,
          otp: otpData?.otp
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .createIndicationV2(params)
        if (!res.status) return

        // this.indicationServices = res.data?.indication || []
        // this.indicationResponse = res.data

        // if (this.type === 4) {
        //   const paramIndication = {
        //     doctor_id: this.$user.doctor.id,
        //     indications: res.data.indications.map((x) => ({
        //       indication_category_id: x.indication_category_id,
        //       indication_id: x.id,
        //       supplier_id: this.selected.find(
        //         (i) => i.indication_category_id === x.indication_category_id
        //       ).target_id,
        //       org_id: this.selected.find(
        //         (i) => i.indication_category_id === x.indication_category_id
        //       ).org_id
        //     })),
        //     order_contact: {
        //       address1: this.patientVisit.person.address,
        //       address2: this.patientVisit.person.address,
        //       name: this.patientVisit.person.name,
        //       phone: this.patientVisit.person.phone
        //     },
        //     order_date: Date.now(),
        //     workspace_id: this.$globalClinic.id
        //   }
        //   const resp = await this.$rf
        //     .getRequest('DoctorRequest')
        //     .postIndicationProductOder(paramIndication)
        //   if (!resp.data) return
        // }
        await this.baseHandle({
          record: this.patientVisit,
          status: EXAMINATION_STATUS.CODE.WAITING_READ_RESULT
        })
        if (hasChecked) {
          this.$refs.ModalEnterOTP.handleSuccess()
        }
        this.isDisabledCreate = true
        this.$message({
          message: 'Tạo phiếu chỉ định thành công',
          type: 'success'
        })
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$message({
          message: 'Tạo phiếu chỉ định thất bại',
          type: 'error'
        })
      } finally {
        this.loading = false
        // this.prepare()
        if (this.prop_indication_order) {
          this.$emit('refresh', {
            reCall: true
          })
        } else {
          this.$emit('refresh', true)
        }
        // this.handlerCancel()
      }
    },
    handlerCancel () {
      this.prepare()
      if (this.prop_indication_order) {
        this.$emit('refresh', {
          reCall: true
        })
      } else {
        this.$emit('refresh')
      }
    },
    setDefaultName () {
      this.form.name =
        this.$t('schedule_page.lbl_indication_sheet') +
        moment().format('DD-MM-YYYY')
    },
    getDataDemo (id) {
      return id ? this.demo[id - 1].name : ''
    },
    handlerIndicationsOrder () {
      if (this.selected.length) return
      this.selected = this.selected.filter(s => !s.id)
      if (this.indication_order?.name) {
        this.form.name = this.indication_order?.name
      } else {
        this.setDefaultName()
      }
      if (this.indicationServices?.length) {
        let _tempSelected = []
        this.indicationServices.map(item => {
          _tempSelected.push({
            ...item,
            indication_category_id: item.service_catalog?.services_category_id,
            name: item.local_name,
            services_code: item.service_catalog?.services_code,
            status: item.status,
            type_service: 2, // Service local
            type_service_id: item.id
            // target_id: item.target_id,
            // target_name: this.getDataDemo(item.target_id),
            // due_date: item.due_date,
          })
        })

        const arr = _tempSelected
        this.selected = arr.map(item => ({
          ...item,
          indicationAmount: item?.indicationAmount || 1
        }))

        // this.selected = _tempSelected
        this.handleSetCheckedKeys()
      }
    },
    goToPrint () {
      this.$router.push({
        name: 'CaseIndicationsPrinter',
        params: { id: this.prop_indication_order.id }
      })
    },
    getCategoryLabel (string) {
      return string?.replaceAll('-', ' &nbsp; ')
    },
    handleCheckChange (data, checked, handle) {
      const _tempSelected = this.selected

      if (!checked) {
        const foundIndex = _tempSelected?.findIndex(item => item.id === data.id)

        if (foundIndex === -1) return
        this.deleted.push(_tempSelected[foundIndex])
        const arr = _tempSelected?.filter(item => item.id !== data.id)
        this.selected = arr.map(item => ({
          ...item,
          indicationAmount: item?.indicationAmount || 1
        }))

        // this.selected =
        if (handle) {
          this.handleSetCheckedKeys()
        }
        return
      }

      const foundData = _tempSelected?.find(item => item.id === data.id)

      if (foundData || typeof foundData !== 'undefined') return
      this.selected = [
        ..._tempSelected,
        {
          ...data,
          id: data.id,
          indication_category_id: data.service_catalog?.services_category_id,
          name: data.name,
          services_code: data.service_catalog?.services_code,
          status: data.status,
          type_service: 2, // Service local
          type_service_id: data.id,
          indicationAmount: 1
          // indication_category_id: data.id,
          // name: data.name,
          // services_code: data.services_code,
          // target_id: 0,
          // target_name: this.getDataDemo(data.target_id),
          // due_date: null,
          // local_service_id: data.local_service_id
        }
      ]
    },
    handleSetCheckedKeys () {
      try {
        if (!this.selected.length) {
          this.$refs.tree.setCheckedKeys([])
          return
        }
        const codes = this.selected?.map(item => item?.id) || []
        this.$refs.tree.setCheckedKeys(codes)
      } catch (error) {}
    },
    handleDeleteIndication (data) {
      if (!data) return
      this.selectedEcomService = this.selectedEcomService?.filter(
        item => item?.id != data?.id
      )
      this.handleCheckChange(data, false, true)
    },
    handleAddReceipt () {
      this.$router.push({
        name: 'CreateIndicationReceipt',
        params: {
          ps_id: this.$route.params.id
        }
      })
    },
    handleRefresh (value) {
      this.ctaTrigger++
      this.handleOpenListReceipt()
      this.handleOpenModalAddReceipt('')
    },
    handleOpenListReceipt () {
      this.$refs.ModalIndicationReceipt.handleOpen()
    },
    handleOpenModalAddReceipt (show) {
      window.$('#modal-add-receipt-form').modal(show ? 'show' : 'hide')
    },
    handleCloseModalIndicationReceipt () {
      this.$emit('refresh')
    },
    getLabelIndication (data) {
      const providerName = data?.service_catalog?.service_provider?.name
        ? `(${data?.service_catalog?.service_provider?.name})`
        : ''

      return `${data.local_name} ${providerName}`
    },
    handleOpenModalSelectServicePublic () {
      this.$refs.ModalSelectServicePublic.handleOpen()
    },
    handleSelectServiceEcom (data) {
      this.selectedEcomService = data
      const _tempSelected = cloneDeep(this.selected)?.filter(
        item => !item.isEcom
      )

      const mapData = data?.map(item => ({
        ...item,
        indication_category_id: item.services_category_id,
        local_name: item.name,
        services_code: item.service_catalog?.services_code,
        status: item.status,
        type_service: 1, // Service catalog
        type_service_id: item.id,
        isEcom: true
      }))
      const arr = [..._tempSelected, ...mapData]
      this.selected = arr.map(item => ({
        ...item,
        indicationAmount: item?.indicationAmount || 1
      }))
    },
    handleOpenModalEnterOTP (user_id) {
      this.$refs.ModalEnterOTP.handleOpen(this.patientVisit, user_id)
    },
    getIndicationName (data) {
      return data?.local_name
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog__body {
    padding: 16px 10px;
  }
  .el-tree-node__content {
    height: 48px;
  }
  .input-deal-date .el-input__inner {
    border: 0;
    background-color: white !important;
    font-size: 14px;
    font-weight: normal !important;
    color: #667085 !important;
  }
  .input-deal-date .el-input__inner::-webkit-input-placeholder {
    color: #667085; /*Change the placeholder color*/
    opacity: 1; /*Change the opacity between 0 and 1*/
  }
}
.h-40px {
  height: 40px;
}
.disable-click {
  pointer-events: none;
  opacity: 0.7;
}
.select-items {
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
}
.select-class {
  overflow-x: auto;
  width: 100%;
}
.class-item {
  white-space: nowrap;
  flex-grow: 1;
}
.delete-indication-wraper {
  width: 40px;
}
.delete-indication {
  display: none;
  cursor: pointer;
}
.btn-add {
  &:hover {
    background: #ecf5ff;
  }
}

.indication-item-hover:hover .delete-indication {
  display: block;
}
.selected-indication {
  padding-right: 8px;
  height: calc(100vh - 300px);
  overflow-y: auto;
}
.selected-indication-item {
  margin-bottom: 16px;
  border-radius: 10px;
  border: 0.5px solid #e6e6e6;
  padding: 8px;
}
.selected-indication-item:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.box-shadow {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.custom-tree-node {
  white-space: pre-wrap;
}

.cs-input {
  max-width: 150px;
  text-align: center;
}
</style>
