import { render, staticRenderFns } from "./ModalEnterOTP.vue?vue&type=template&id=4f522126&scoped=true"
import script from "./ModalEnterOTP.vue?vue&type=script&lang=js"
export * from "./ModalEnterOTP.vue?vue&type=script&lang=js"
import style0 from "./ModalEnterOTP.vue?vue&type=style&index=0&id=4f522126&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f522126",
  null
  
)

export default component.exports