<template>
  <div>
    <div v-if="org && isShowWarning" class="relative flex justify-between fs-18 mb-3 p-3" style="background-color: #ffefd9">
      <button @click="() => (isShowWarning = false)" type="button" class="absolute top-0 right-2 bg-transparent text-black fs-24 border-0 p-2" style="outline: none !important">
        <span class="cursor-pointer">&times;</span>
      </button>
      <div class="flex gap-4">
        <div class="flex items-center">
          <WarningIcon />
        </div>
        <div class="flex fs-20 items-center">
          {{ $t("blank_state.lbl_org") }}
          <p class="font-bold mb-0 mx-1">{{ org.name }}</p>
          {{ $t("blank_state.lbl_not_kyc") }}
        </div>
      </div>
      <button type="button" class="btn bg-pri bd-pri text-white w-40 p-2 h-10 ml-2 self-end mt-5" @click="handleRequestKycOrg()">{{ $t("blank_state.btn_kyc_org") }}</button>
    </div>
    <div class="container radius-10 bg-white px-3 py-4">
      <div class="mr-auto">
        <div class="pb-3">
          <p class="mt-2 fs-28 fw-700 txt-grey-900">{{ $t("blank_state.lbl_welcome") }} {{ thisDoctor.name }}!</p>
          <!-- <div
             class="fs-18 fw-400 txt-grey-700"
           >{{$t('blank_state.lbl_wel_msg_org')}}</div> -->
        </div>
      </div>
      <div align="center" class="container mt-3">
        <img class="wh-580" src="../../public/assets/images/welcome-org.png" />
        <p class="mt-2 fs-18 fw-400 txt-grey-700">{{ $t("blank_state.lbl_deployment_org") }}</p>
        <p class="fs-18 fw-400 txt-grey-700 text-center">{{ $t("blank_state.lbl_start_now") }}</p>
        <div class="d-flex align-items-start justify-content-center">
          <div class="w-300px px-1">
            <button type="button" class="w-100 btn bg-pri bd-pri text-white mr-2" @click="showCreate()">{{ $t("blank_state.lbl_setting_org") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import { WarningIcon } from '../components/Icon'
import appUtils from '../utils/appUtils'
import CodeInvitesOrg from './CodeInvitesOrg.vue'
import CreateAddOrSelect from './CreateAddOrSelect.vue'
import CreateOrg from './CreateOrg.vue'
export default {
  name: 'JoinOrgV2',
  components: { CodeInvitesOrg, CreateAddOrSelect, CreateOrg, WarningIcon },
  data () {
    return {
      org: null,
      orgId: null,
      loading: false,
      is_have_clinic: false,
      is_have_org: false,
      is_show: false,
      is_alert: false,
      is_show_code_invites: false,
      is_show_not_Workspace: false,
      isShowWarning: false,
      params: {
        type: 'clinic',
        code: ''
      }
    }
  },
  computed: {
    thisDoctor () {
      let user = appUtils.getLocalUser()
      if (user.doctor) {
        return user
      }
    }
  },
  watch: {
    '$route.query.t': {
      deep: true,
      handler (t) {
        if (this.$route.query.type) {
          if (this.$route.query.type === 'create') {
            this.showCreate()
          }
          if (this.$route.query.type === 'join') {
            this.onOpenCodeInvites()
          }
        }
      }
    }
  },
  beforeMount () {
    this.orgId = this.$globalOrg?.id
  },
  mounted () {
    this.getOrg(this.orgId)
    this.getClinics()
    if (this.$route.query.type) {
      if (this.$route.query.type === 'create') {
        this.showCreate()
      }
      if (this.$route.query.type === 'join') {
        this.onOpenCodeInvites()
      }
    }
    this.checkKycOrg()
  },
  methods: {
    async getOrg (id) {
      if (!id) return
      const res = await this.$rf.getRequest('DoctorRequest').getOrg(id)
      if (res.data) {
        this.is_have_org = true
        this.org = res.data
      }
    },
    async getClinics () {
      let self = this
      self.loading = true
      try {
        let resp = await self.$rf.getRequest('DoctorRequest').getClinic()
        if (resp && resp.data && resp.data?.length) {
          let is_have_clinic = resp.data?.filter(clinic => clinic.doctor_id === this.thisDoctor.doctor.id).length
          if (is_have_clinic) {
            self.is_have_clinic = true
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        // statements
      }
    },
    onAddClinic () {
      this.onShowModalOpitonClinic(true)
      // let self = this
      // if (!self.is_have_clinic) {
      //   self.onShowModalJoin(true)
      // } else {
      //   self.onShowModalOpitonClinic(true)
      // }
    },
    onShowModalOpitonClinic (show) {
      window.$('#modal__option').modal(show ? 'show' : 'hide')
    },
    onShowModalCreate (show) {
      window.$('#modal__create').modal(show ? 'show' : 'hide')
    },
    onShowModalJoin (show) {
      window.$('#modal__join').modal(show ? 'show' : 'hide')
    },
    onOpenCodeInvites () {
      let self = this
      self.onShowModalOpitonClinic(false)
      self.onShowModalJoin(true)
    },
    showCreate () {
      let self = this
      self.onShowModalOpitonClinic(false)
      self.onShowModalCreate(true)
    },
    closeShowInvites () {
      let self = this
      // self.onShowModalOpitonClinic(!self.is_have_org)
      self.onShowModalJoin(false)
    },
    closeShowCreate () {
      let self = this
      // self.onShowModalOpitonClinic(true)
      self.onShowModalCreate(false)
    },
    async checkKycOrg () {
      if (!this.orgId) return
      try {
        const res = await this.$rf.getRequest('DoctorRequest').getSubOrganizationDetail(this.orgId)
        this.isShowWarning = get(res.data, 'org_hodo_services.service_status') === 0
      } catch (error) {
        console.log(error)
      }
    },
    handleRequestKycOrg () {
      console.log('request KYC ORG')
    }
  }
}
</script>
   <style scoped>
.wh-350 {
  width: 350px;
}
.w-200px {
  width: 100%;
  max-width: 200px;
}
</style>
