<template>
  <div>
    <el-dialog :visible.sync="isOpen" width="40%" append-to-body center>
      <template slot="title">
        <div class="fs-20 font-bold uppercase text-black">
          {{ $t("Yêu cầu đồng thuận từ Bệnh nhân") }}
        </div>
      </template>
      <div class="text-black">
        <p>
          <strong>{{
            $t("Bệnh nhân đồng ý chia sẻ thông tin chỉ định y khoa. Cụ thể là:")
          }}</strong>
        </p>
        <ol>
          <li style="word-break: break-word">
            {{
              $t(
                "Bệnh nhân chấp thuận cho phép Bác Sĩ, Nhân Viên Y Tế yêu cầu dịch vụ cận lâm sàng tại Đơn vị này (đã cung cấp trong nội dung tin nhắn OTP)"
              )
            }}
          </li>
          <li class="mt-2" style="word-break: break-word">
            {{
              $t(
                " Bệnh nhân chấp thuận cho phép Bác Sĩ, Nhân Viên Y Tế, Cơ Sở Y Tế, Cơ Sở Cung Cấp Dịch Vụ Y Tế và Nền Tảng Công Nghệ Thông Tin chia sẻ dữ liệu liên quan đến chỉ định y khoa. Cụ thể là:"
              )
            }}
            <ul>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu hành chính (bao gồm thông tin cá nhân)") }}
              </li>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu chẩn đoán") }}
              </li>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu lâm sàng kèm theo") }}
              </li>
              <li style="word-break: break-word">
                {{ $t("Dữ liệu chỉ định y khoa") }}
              </li>
            </ul>
          </li>
        </ol>
        <p style="word-break: break-word; font-style: italic">
          {{
            $t(
              "Lưu ý: Dữ liệu được chia sẻ hoàn toàn nhằm mục đích đảm bảo chất lượng Dịch Vụ Xét Nghiệm, đảm bảo an toàn Bệnh Nhân, Nhân Viên Y Tế, và các Đơn Vị Cung Cấp Dịch Vụ."
            )
          }}
        </p>
      </div>
      <div
        class="flex flex-col md:flex-row items-center gap-2 justify-end mt-4 text-white"
      >
        <el-button @click="handleClose">{{ $t("Đóng") }}</el-button>
        <el-button
          :disabled="isLoading"
          class="text-white"
          type="primary"
          @click="handleSendOTPEcomIndication"
          >{{ $t("Gửi OTP tới Bệnh nhân") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'MOdalConfirmConsent',
  data () {
    return {
      isOpen: false,
      patientVisit: {},
      isLoading: false
    }
  },
  methods: {
    handleOpen (data) {
      this.isOpen = true
      this.patientVisit = data
    },
    handleClose () {
      this.isOpen = false
    },
    async handleSendOTPEcomIndication () {
      try {
        this.isLoading = true
        const params = {
          phone: this.patientVisit?.person?.phone
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .sendOTPVerifyIndication(params)

        if (response.status === 200) {
          this.$message({
            message: 'Gửi mã OTP đến bệnh nhân thành công',
            type: 'success'
          })
          this.handleClose()
          this.$emit('onSuccess', response.data?.user_id)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>