<template>
  <div class="container radius-10 bg-white p-3">
    <div class="mr-auto">
      <div class="pb-3">
        <p class="mt-2 fs-28 fw-700 txt-grey-900">{{ $t("blank_state.lbl_welcome") }} {{ thisDoctor.name }}!</p>
        <!-- <div v-if="!is_have_clinic" class="fs-18 fw-400 txt-grey-700">
          {{ $t("blank_state.lbl_wel_msg") }}
        </div>-->
        <div v-if="is_have_clinic" class="fs-18 fw-400 txt-grey-700">{{ $t("blank_state.lbl_wel_benifit_msg")[$orgModel] }}</div>
      </div>
    </div>
    <div align="center" class="container mt-3">
      <img class="wh-350" src="../../public/assets/images/img-thinking.svg" />
      <!-- <p v-if="!is_have_clinic" class="mt-2 fs-18 fw-400 txt-grey-700">
        {{ $t("blank_state.lbl_nows_msg")[$orgModel] }}
      </p>-->
      <p v-if="!is_have_clinic" class="mt-2 fs-18 fw-400 txt-grey-700">{{ $t("blank_state.lbl_build_ws_msg")[$orgModel] }}</p>
      <p v-if="is_have_clinic" class="fs-18 fw-400 txt-grey-700 text-center">{{ $t("blank_state.lbl_wel_chose_msg")[$orgModel] }}</p>
      <p v-if="is_have_clinic" class="mt-2 fs-18 fw-400 txt-grey-700">{{ $t("blank_state.lbl_wel_start_msg") }}</p>

      <div class="d-flex flex-col md:flex-row align-items-start justify-content-center">
        <div class="w-200px px-1 mb-3 mb-md-0">
          <button type="button" class="w-100 btn bg-pri bd-pri text-white mr-2" @click="showCreate()">{{ $t("blank_state.btn_create_ws")[$orgModel] }}</button>
        </div>
        <div class="w-200px px-1">
          <button type="button" class="btn bg-pri bd-pri text-white w-100" @click="onOpenCodeInvites()">{{ $t("blank_state.btn_join_ws")[$orgModel] }}</button>
        </div>
      </div>
    </div>
    <br />
    <div class="modal fade" id="modal__option">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("blank_state.lbl_wel_add_ws") }}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-1 pb-3">
            <CreateAddOrSelect @showCreate="showCreate" @onOpenCodeInvites="onOpenCodeInvites"></CreateAddOrSelect>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal__join">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("blank_state.btn_join_ws")[$orgModel] }}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-1 pb-3">
            <CodeInvitesClinic @closeShowInvites="closeShowInvites"></CodeInvitesClinic>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal__create">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t("blank_state.btn_create_ws")[$orgModel] }}</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body p-1 pb-3">
            <CreateClinic @closeShowCreate="closeShowCreate"></CreateClinic>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appUtils from '../utils/appUtils'
import CodeInvitesClinic from './CodeInvitesClinic.vue'
import CreateAddOrSelect from './CreateAddOrSelect.vue'
import CreateClinic from './CreateClinic.vue'
export default {
  name: 'JoinClinic',
  components: { CodeInvitesClinic, CreateAddOrSelect, CreateClinic },
  data () {
    return {
      loading: false,
      is_have_clinic: false,
      is_show: false,
      is_alert: false,
      is_show_code_invites: false,
      is_show_not_Workspace: false,
      params: {
        type: 'clinic',
        code: ''
      }
    }
  },
  computed: {
    thisDoctor () {
      let user = appUtils.getLocalUser()
      if (user.doctor) {
        return user
      }
    }
  },
  watch: {
    '$route.query.t': {
      deep: true,
      handler (t) {
        if (this.$route.query.type) {
          if (this.$route.query.type === 'create') {
            this.showCreate()
          }
          if (this.$route.query.type === 'join') {
            this.onOpenCodeInvites()
          }
        }
      }
    }
  },
  mounted () {
    this.getClinics()
    if (this.$route.query.type) {
      if (this.$route.query.type === 'create') {
        this.showCreate()
      }
      if (this.$route.query.type === 'join') {
        this.onOpenCodeInvites()
      }
    }
  },
  methods: {
    async getClinics () {
      let self = this
      self.loading = true
      try {
        let resp = await self.$rf.getRequest('DoctorRequest').getClinic()
        if (resp && resp.data && resp.data?.length) {
          let is_have_clinic = resp.data?.filter(clinic => clinic.doctor_id === this.thisDoctor.doctor.id).length
          if (is_have_clinic) {
            self.is_have_clinic = true
          }
        }
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        // statements
      }
    },
    onAddClinic () {
      this.onShowModalOpitonClinic(true)
      // let self = this
      // if (!self.is_have_clinic) {
      //   self.onShowModalJoin(true)
      // } else {
      //   self.onShowModalOpitonClinic(true)
      // }
    },
    onShowModalOpitonClinic (show) {
      window.$('#modal__option').modal(show ? 'show' : 'hide')
    },
    onShowModalCreate (show) {
      window.$('#modal__create').modal(show ? 'show' : 'hide')
    },
    onShowModalJoin (show) {
      window.$('#modal__join').modal(show ? 'show' : 'hide')
    },
    onOpenCodeInvites () {
      let self = this
      self.onShowModalOpitonClinic(false)
      self.onShowModalJoin(true)
    },
    showCreate () {
      let self = this
      self.onShowModalOpitonClinic(false)
      self.onShowModalCreate(true)
    },
    closeShowInvites () {
      let self = this
      // self.onShowModalOpitonClinic(!self.is_have_clinic)
      self.onShowModalJoin(false)
    },
    closeShowCreate () {
      let self = this
      // self.onShowModalOpitonClinic(true)
      self.onShowModalCreate(false)
    }
  }
}
</script>
<style scoped>
.wh-350 {
  width: 350px;
}
.w-200px {
  width: 100%;
  max-width: 200px;
}
</style>
