<template>
  <div>
    <div
      class="modal p-0"
      tabindex="-1"
      role="dialog"
      id="modal-add-receipt-form"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" align="left">Tạo phiếu thu</h5>
            <span class="btn--close"></span>
          </div>
          <div v-loading="isLoading" class="modal-body">
            <el-form
              :rules="rules"
              ref="receiptForm"
              :model="form"
              label-position="top"
              class="flex items-start justify-between gap-4"
            >
              <div class="flex-1">
                <!-- <el-form-item label="Số phiếu thu" prop="receipt_number">
                  <el-input
                    v-model="form.receipt_number"
                    type="number"
                    placeholder="Nhập số phiếu thu"
                  ></el-input>
                </el-form-item> -->
                <div class="flex items-center gap-3">
                  <el-form-item label="Mã bệnh nhân" class="flex-1">
                    <el-input
                      v-model="form.patient_id"
                      :disabled="true"
                      class="disable"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Họ tên bệnh nhân" class="flex-2">
                    <el-input
                      v-model="form.full_name"
                      :disabled="true"
                      class="disable"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Giới tính" class="flex-1">
                    <el-input
                      v-model="form.gender"
                      :disabled="true"
                      class="disable"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Năm sinh" class="flex-1">
                    <el-input
                      v-model="form.date_of_birth"
                      :disabled="true"
                      class="disable"
                    ></el-input>
                  </el-form-item>
                </div>
                <el-form-item label="Địa chỉ" prop="address">
                  <el-input
                    v-model="form.address"
                    placeholder="Nhập địa chỉ"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Chẩn đoán" prop="diagnostic">
                  <el-input
                    v-model="form.diagnostic"
                    placeholder="Nhập chẩn đoán"
                  ></el-input>
                </el-form-item>
                <div class="flex items-center justify-between gap-3">
                  <el-form-item
                    label="Người lập phiếu"
                    class="flex-1"
                    prop="created_person"
                  >
                    <el-input
                      v-model="form.created_person"
                      placeholder="Nhập tên người lập phiếu"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Ngày lập phiếu"
                    class="flex-1"
                    prop="created_date"
                  >
                    <el-date-picker
                      v-model="form.created_date"
                      type="date"
                      placeholder="Chọn ngày"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="flex-1">
                <el-form-item label="Danh sách dịch vụ" prop="list_services">
                  <el-checkbox
                    v-for="(item, index) in indicationServices"
                    :key="index"
                    v-model="item.checked"
                  >
                    <div class="flex items-center justify-between w-full">
                      <p>{{ item.name }}</p>
                      <p>{{ getPrice(item) }} x 1 lần</p>
                    </div>
                  </el-checkbox>
                </el-form-item>
                <div class="flex justify-between">
                  <span>{{ $t("Tổng tiền dịch vụ") }}</span>
                  <span>{{ totalPrice.priceFormat }}</span>
                </div>
                <!-- <el-form-item label="Tổng tiền dịch vụ">
                  <el-input v-model="totalPrice" disabled class="total disable">
                    <template slot="append">VNĐ</template>
                  </el-input>
                </el-form-item> -->
              </div>
            </el-form>
          </div>
          <div class="modal-footer d-flex justify-content-end">
            <el-button
              type
              class="radius-0 bg-pr px-3 py-2 rounded-lg"
              @click="onCancel('receiptForm')"
            >
              <span class="px-3 py-2 text-base">Huỷ bỏ</span>
            </el-button>
            <el-button
              type="primary"
              class="radius-0 px-3 py-2 rounded-lg"
              @click="onSubmit()"
            >
              <span class="px-3 py-2 text-base">Lưu</span>
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import appUtils from '@/utils/appUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'

export default {
  name: 'ModalAddReceiptForm',
  mixins: [mixinGetPersonInfo],

  props: {
    indicationResponse: Object
  },
  data () {
    return {
      form: {
        receipt_number: '',
        patient_id: '',
        full_name: '',
        gender: '',
        date_of_birth: '',
        address: '',
        diagnostic: '',
        created_person: '',
        created_date: new Date(),
        total: 0,
        real_price: 0
      },
      indicationServices: [],
      rules: {},
      isLoading: false
    }
  },
  watch: {
    indicationResponse: {
      deep: true,
      handler () {
        this.handleMapData()
      }
    }
  },
  computed: {
    totalPrice () {
      const price = this.indicationServices
        ?.filter((item) => item?.checked)
        ?.reduce((total, item) => total + item?.local_service?.local_price, 0)

      return {
        priceFormat: appUtils.numberFormat(price, 'VND'),
        price
      }
    }
  },
  mounted () {
    this.handleMapData()
  },
  methods: {
    onCancel (form) {
      this.resetForm(form)
      this.$emit('cancel')
    },
    onSubmit () {
      this.$refs.receiptForm.validate((valid) => {
        if (valid) {
          try {
            this.handleCreateIndicationReceipt()
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$message({
            type: 'warning',
            message: this.$t(`Vui lòng điền đủ dữ liệu`)
          })
          return false
        }
      })
      // this.$emit('submit', this.form)
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    handleMapData () {
      const clonedData = { ...this.indicationResponse }

      this.form = {
        patient_id: clonedData?.person?.pid,
        full_name: clonedData?.person?.name,
        gender: clonedData?.person?.gender === 1 ? 'Nam' : 'Nữ',
        date_of_birth: clonedData?.person?.birthday
          ? moment(clonedData?.person?.birthday).format('YYYY')
          : '',
        address: this.getAddress(clonedData?.person) || '',
        created_person: this.$user?.name,
        created_date: new Date()
      }

      this.indicationServices = clonedData?.indication?.map((item) => ({
        ...item,
        checked: true
      }))
    },
    getPrice (data) {
      return data?.local_service?.local_price
        ? appUtils.numberFormat(data?.local_service?.local_price)
        : 0
    },
    async handleCreateIndicationReceipt () {
      try {
        const params = {
          order_items: this.indicationServices
            ?.filter((item) => item?.checked)
            ?.map((item) => {
              return {
                local_service_id: item?.local_service?.id,
                price: item?.local_service?.local_price,
                product_id:
                  item?.local_service?.service_catalog?.partner_product_id,
                qty: 1,
                type: 2, // Xét nghiệm,
                total_amount: item?.local_service?.local_price * 1
              }
            }),
          service_transaction_info: {
            address: this.form.address,
            amount: this.totalPrice.price,
            diagnostic: this.form.diagnostic,
            originate_by: 1, // 1 là bs đặt
            person_gender: this.indicationResponse?.person?.gender,
            person_id: this.indicationResponse?.person?.id,
            person_name: this.form.full_name,
            person_yob: this.form.date_of_birth,
            email: this.indicationResponse?.person?.email,
            phone: this.indicationResponse?.person?.phone,
            pid: this.form.patient_id,
            referrer_id: this.$user?.id,
            referrer_workspace_id: this.$globalClinic?.id,
            transaction_type: 1, // 1: labs, 2: home_doctor, 3: examination (khám) 4: consultation
            indication_order_id: this.indicationResponse?.id
          },
          order_date: window.moment().valueOf(),
          org_id: this.$globalClinic?.org_id
        }

        this.isLoading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .createIndicationReceipt(params)

        if (response.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t(`Tạo phiếu thu thành công`)
          })

          this.onCancel('receiptForm')
          this.$emit('onRefresh', response.data)
        }
      } catch (error) {
        console.log(error)
        this.$message({
          type: 'error',
          message: this.$t(`Có lỗi xảy ra khi tạo phiếu thu`)
        })
      } finally {
        this.isLoading = false
      }
    }
    // emtyValidate() {
    //   let self = this
    //   self.results.forEach(function(element, index) {
    //     element.is_error = false
    //     element.error = null
    //   })
    // }
  }
}
</script>
<style scoped lang="scss">
.input[type="text"] {
  color: #222222 !important;
  font-family: "roboto";
  font-size: 16px;
  font-weight: 500;
}
.modal-content {
  border: 1px solid #e6e8e9 !important;
  border-radius: 0 !important;
}
.modal-header {
  background: unset;
  color: #000;
  display: flex;
  align-items: flex-start;
}
.modal-footer {
  border-top: 1px solid #e6e8e9;
  padding: 12px 18px;
}
::v-deep {
  .el-form-item {
    .el-form-item__label {
      padding-bottom: 0;
      line-height: 20px;
    }
  }
  .el-input__inner {
    background: white !important;
    border: 1px solid #dee2e6 !important;
    transition: all 0.3s ease;
    font-weight: 400 !important;
    &:focus {
      border-color: #a7abb1 !important;
    }
  }
  .el-checkbox {
    width: 100%;
    .el-checkbox__label {
      width: calc(100% - 14px);
    }
  }
  .disable {
    .el-input__inner {
      background: #dbdbdb !important;
    }
  }
  .total {
    .el-input__inner {
      text-align: right;
      border-radius: unset !important;
      //   padding-right: 40px;
    }
    .el-input__suffix {
      margin-right: 12px;
    }
  }
}

.modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  border-radius: 0px;
}
</style>