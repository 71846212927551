<template>
  <div>
    <el-dialog :visible.sync="isOpen" width="30%" append-to-body center>
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ $t("Nhập OTP") }}
        </div>
      </template>
      <div v-loading="isLoading" class="mb-4 flex justify-center">
        <OTPInput
          v-model="otpCode"
          :length="4"
          :ignorePattern="false"
          class="style-2 p-0 m-0 list-none"
          fieldClass="inline-block overflow-hidden align-top"
          size="32"
          @change="handleDebounce"
        />
      </div>
      <div
        class="flex flex-col md:flex-row items-center gap-2 justify-center mt-4 text-white"
      >
        <el-button
          :disabled="Boolean(countDown) || isLoading"
          class="text-white w-full"
          type="primary"
          @click="handleResendOTP"
          >{{ $t("Gửi lại OTP")
          }}{{ countDown ? `(${countDown}s)` : "" }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import OTPInput from '@8bu/vue-otp-input'
import { debounce } from 'lodash'

export default {
  name: 'ModalEnterOTP',
  components: { OTPInput },
  data () {
    return {
      isOpen: false,
      patientVisit: {},
      otpCode: '',
      countDown: 0,
      user_id: null,
      isLoading: false
    }
  },

  methods: {
    handleOpen (data, user_id) {
      this.isOpen = true
      this.patientVisit = data
      this.user_id = user_id
      this.handleSetCountDown()
    },
    handleClose () {
      this.isOpen = false
      this.otpCode = ''
    },
    async handleSubmit () {
      if (!this.otpCode?.length || this.otpCode?.length < 4) return
      this.isLoading = true

      try {
        const params = {
          otp: Number(this.otpCode),
          user_id: this.user_id
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .checkOTPVerifyIndication(params)

        if (response.status === 200) {
          this.$emit('onSuccess', true, {
            user_id: this.user_id,
            otp: Number(this.otpCode)
          })
        }
      } catch (error) {
        console.log(error)
        this.isLoading = false
        this.$message({
          message: 'Nhập sai mã OTP',
          type: 'error'
        })
      }
    },
    async handleResendOTP () {
      try {
        const params = {
          phone: this.patientVisit?.person?.phone
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .sendOTPVerifyIndication(params)

        if (response.status === 200) {
          this.handleSetCountDown()
          this.$message({
            message: 'Gửi mã OTP đến bệnh nhân thành công',
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleSuccess () {
      this.handleClose()
      this.isLoading = false
    },
    // handleSetCountDown () {
    //   const self = this
    //   this.countDown = 30

    //   setInterval(() => {
    //     self.countDown = self.countDown - 1
    //   }, 1000)
    // },
    handleSetCountDown () {
      const self = this
      this.countDown = 30

      let intervalMs

      intervalMs = setInterval(() => {
        self.countDown = self.countDown - 1

        if (self.countDown <= 0) {
          clearInterval(intervalMs)
        }
      }, 1000)
    },
    handleDebounce: debounce(function () {
      this.handleSubmit()
    }, 500)
  }
}
</script>


<style lang="scss" scoped>
::v-deep {
  .overflow-hidden {
    overflow: hidden;
  }
  .y100-auto {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .sign-form-wraper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .form-input {
    color: #1d2939;
    border: 0.1px solid #98a2b3;
    padding: 8px;
    border-radius: 12px;
  }
  .form-btn {
    color: white;
    border-radius: 12px;
    padding: 12px;
  }
  .form-btn:disabled {
    background-color: #667085;
  }
  .txt-msg {
    color: #98a2b3;
  }
  .txt-success {
    color: #027a48;
  }

  .w-100-678px {
    width: 100%;
    max-width: 678px;
    margin: 0 auto;
  }
  .w-100-441px {
    width: 100%;
    max-width: 441px;
    margin: 0 auto;
  }
  .h-100vh {
    height: 100vh;
  }
  .centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .code {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 25px;
    margin: 0px 5px;
    border: 0px;
    border-bottom: 1px solid black;
    &::placeholder {
      color: #f5f5f5;
    }
    &::-moz-placeholder {
      color: #f5f5f5;
    }
    &::-webkit-input-placeholder {
      color: #f5f5f5;
    }
  }
  .code:focus,
  .code:focus-visible {
    outline: 0px;
    color: #ef3a3a;
    border-color: #ef3a3a !important;
  }

  .otp-input-8 {
    display: block;
    line-height: 1;
    padding-left: 0;
    margin: 0;
  }

  .otp-input-8-field {
    display: inline-block;
    vertical-align: top;
    font-size: inherit;
    overflow: hidden;
  }

  .otp-input-8-field input {
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    width: 1.75em;
    height: 1.75em;
    font-size: inherit;
    line-height: 1.75em;
    text-align: center;
    outline: none;
  }

  .style-2 {
    .otp-input-8-field {
      position: relative;
      &::after {
        content: "";
        border-radius: 9999px;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        display: block;
        height: 4px;
        background-color: #000;
      }
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      input {
        background: transparent;
        color: #000;
        font-weight: bold;
      }
    }
  }
}
</style>
