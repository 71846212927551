<template>
  <el-dialog
    custom-class="px-4"
    :visible.sync="isOpen"
    fullscreen
    append-to-body
  >
    <template slot="title">
      <div class="fs-20 font-bold uppercase">
        {{ $t("DỊCH VỤ THƯƠNG MẠI ĐIỆN TỬ") }}
      </div>
    </template>

    <div>
      <div class="flex flex-col md:flex-row gap-3 items-center cs-container">
        <div class="flex-1">
          <label>{{ $t("Tìm kiếm") }}</label>
          <el-input
            class="cs-input"
            :placeholder="$t('Nhập tên dịch vụ, mã dịch vụ')"
            v-model="searchValue"
            @input="handleDebounce"
            clearable
          >
            <el-button disabled slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <!-- <div style="max-width: 400px; width: 100%">
          <label class="block">{{ $t("Đơn vị cung cấp") }}</label>
          <el-select
            v-model="provider_id"
            :placeholder="$t('Chọn đơn vị cung cấp')"
            class="w-full"
          >
            <el-option
              v-for="item in providers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>

      <div class="cs-container mt-2">
        <el-table
          ref="tableSelectedService"
          :data="services"
          style="width: 100%"
          row-key="id"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            reserve-selection
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column prop="services_code" label="Mã dịch vụ">
          </el-table-column>
          <el-table-column prop="name" label="Tên dịch vụ" width="400px">
          </el-table-column>
          <el-table-column prop="service_provider.name" label="Đơn vị cung cấp">
          </el-table-column>
          <el-table-column prop="service_category.name" label="Danh mục">
          </el-table-column>
          <!-- <el-table-column prop="service_category.name" label="Loại">
          </el-table-column> -->
          <el-table-column label="Giá">
            <template slot-scope="scope"
              ><span class="text-right">{{
                appUtils.numberFormat(
                  (scope.row && scope.row.price) || 0,
                  "VND"
                )
              }}</span></template
            >
          </el-table-column>
        </el-table>
      </div>
      <AppPagination
        :paginationProps="pagination"
        @onChangePagination="handleGetServicePublic"
      />

      <div
        class="flex flex-col md:flex-row items-center gap-2 justify-end mt-4 text-white"
      >
        <el-button @click="handleClose">{{ $t("Huỷ") }}</el-button>
        <el-button class="text-white" type="primary" @click="handleSubmit">{{
          $t("Chọn")
        }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { debounce } from 'lodash'
import appUtils from '../../../../utils/appUtils'
import { AppPagination } from '../../../Common'

export default {
  name: 'ModalSelectServicePublic',
  components: { AppPagination },
  props: { selectedServiceProp: Array },
  data () {
    return {
      isOpen: false,
      searchValue: '',
      provider_id: null,
      providers: [],
      services: [],
      selectedServices: [],
      appUtils,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      }
    }
  },

  mounted () {
    this.handleGetServicePublic()
  },
  methods: {
    handleClose () {
      this.isOpen = false
      this.searchValue = ''
    },
    handleOpen () {
      this.isOpen = true
      this.handleMapSelected()
    },
    handleSubmit () {
      this.$emit('onSelected', this.selectedServices)
      this.handleClose()
    },
    handleSelectionChange (data) {
      this.selectedServices = data
    },
    async handleGetServicePublic (data) {
      const params = {
        keyword: this.searchValue,
        page_size: data?.pageSize || this.pagination.pageSize,
        page_num: data?.currentPage || this.pagination.currentPage,
        is_market: 2
      }
      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListService(params)
        const responseData = response.data?.data || []
        this.services = responseData?.map((item) => ({
          ...item
          // indicationAmount: 1
        }))
        this.pagination = {
          currentPage: response.data?.page?.page_num || this.pagination.page,
          pageSize: response.data?.page?.page_size || this.pagination.pageSize,
          totalItems: response.data?.page?.total || 0
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleMapSelected () {
      if (this.selectedServiceProp?.length) {
        this.$refs.tableSelectedService &&
          this.$refs.tableSelectedService.clearSelection()

        this.services.forEach((item) => {
          const index = this.selectedServiceProp.findIndex(
            (selectedItem) => selectedItem?.id === item?.id
          )
          const isSelected = index !== -1
          if (isSelected) {
            this.$refs.tableSelectedService.toggleRowSelection(item, true)
          }
        })
      } else {
        this.$refs.tableSelectedService &&
          this.$refs.tableSelectedService.clearSelection()
      }
    },
    handleDebounce: debounce(function () {
      this.handleGetServicePublic()
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border: 1px solid #dcdfe6 !important;
      background-color: white !important;
    }

    .el-input-group__append {
      background-color: white !important;
    }
  }

  .cs-container * {
    color: black !important;
  }
}
</style>