<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :fullscreen="true"
    :before-close="handleClose"
    append-to-body
    modal-append-to-body
  >
    <CUInficationOrder
      v-if="dialogVisible"
      :consultation_id="consultation_id"
      :mr_id="mr_id"
      :hr_id="hr_id"
      @refresh="refresh"
      :prop_indication_order="indication_order"
    />
  </el-dialog>
</template>
      
<script>
// import JsBarcode from 'jsbarcode'
import CUInficationOrder from './CUInficationOrder.vue'
export default {
  props: ['mr_id', 'hr_id', 'consultation_id'],
  name: 'ModalCreateIndication',
  components: { CUInficationOrder },
  data () {
    return {
      dialogVisible: false,
      indication_order: null
    }
  },
  methods: {
    openDialog (type, item) {
      this.indication_order = item
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
      this.indication_order = null
    },
    refresh (item) {
      this.$emit('refresh')
      this.handleClose()
      if (item && this.$route.name !== 'ApptCall') {
        setTimeout(() => {
          this.openDialog('update', item)
        }, 100)
      }
    }
  }
}
</script>
      
<style scoped lang="scss">
</style>