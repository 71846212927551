<template>
  <el-dialog :visible.sync="showIndicationOrderDetail" :before-close="onHideModal" width="75%">
    <div class="text-black" v-if="indicationOrderDetail" v-loading="isLoading">
      <div class="border-b border-l-0 border-t-0 border-r-0 border-solid border-gray-200 pb-2 mb-3">
        <div class="flex flex-wrap items-center justify-between -mx-3">
          <div class="px-3">
            <h3 class="mb-0">{{ indicationOrderDetail.name }}</h3>
            <div class="text-xs italic">
              <span class="mr-1">Cập nhật lần cuối:</span>
              <span>{{ appUtils.formatDateTime(indicationOrderDetail.updated_at) }}</span>
            </div>
          </div>
          <div class="flex items-center px-3">
            <a
              v-if="indicationResultDocumentLink"
              :href="indicationResultDocumentLink"
              target="_blank"
              class="py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid border-blue-300 text-blue-800 bg-blue-50 hover:bg-blue-200 transition-all duration-200"
            >
              <i class="el-icon-document" />
              <span class="ml-1">{{ $t("Phiếu kết quả") }}</span>
            </a>
            <button
              v-if="!indicationOrderDetail.sign"
              @click="handleSignIndicationOrder"
              type="button"
              class="py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid border-blue-300 text-blue-800 bg-blue-50 hover:bg-blue-200 transition-all duration-200"
            >
              <i class="el-icon-circle-check" />
              <span class="ml-1">{{ $t("Ký phiếu") }}</span>
            </button>
            <div v-else role="button" class="text-xs text-center leading-none">
              <div class="font-bold mb-1">{{ $t("Đã ký phiếu") }}</div>
              <div class="mb-1">{{ indicationOrderDetail.sign.user.name }}</div>
              <div>{{ getHMDMYVN(indicationOrderDetail.sign.sign_at) }}</div>
            </div>
            <button
              @click="goToPrint"
              type="button"
              class="py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid border-blue-300 text-blue-800 bg-blue-50 hover:bg-blue-200 transition-all duration-200"
            >
              <i class="el-icon-printer" />
              <span class="ml-1">{{ $t("multidisciplinary_board.lbl_print") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div>
        <h3 class="mb-2">{{ $t("schedule_page.lbl_indication_list") }}</h3>
        <div class="mb-3">
          <div
            v-for="item in indicationOrderDetail.indication"
            :key="item.id"
            class="group mb-2 cursor-pointer"
            @click="onHandleViewIndicationResult(item)"
          >
            <h4
              class="border-b border-l-0 border-t-0 border-r-0 border-solid border-gray-200 bg-gray-100 group-hover:bg-gray-200 py-1 px-2 mb-0 transition-all duration-200"
            >
              <span>{{ item.name }}</span>
            </h4>
            <div class="flex justify-between items-center p-2">
              <div class="w-1/3">
                <i class="el-icon-map-location" />
                <span class="ml-1">
                  {{ item.target_name ? item.target_name : item.target_id ? getNameTargetIndication(item.target_id) :
                  $t("schedule_page.lbl_no_exe_place") }}
                </span>
              </div>
              <div class="w-1/3 text-center">
                <i class="el-icon-date" />
                <span class="ml-1">
                  {{ item.due_date ? appUtils.formatDate(item.due_date) :
                  $t("schedule_page.lbl_no_due_date") }}
                </span>
              </div>
              <div class="w-1/3 italic text-right" :set="(status = hasExaminationResults(item))">
                <span :class="status.class">{{ status.text }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3" v-if="showResult && tableData.length">
          <div class="p-3 border h-full hodo-print">
            <div class="row text-center mb-3">
              <div class="fs-24 font-bold" style="text-transform: uppercase">Kết quả xét nghiệm</div>
            </div>

            <!-- Thông tin cơ bản của xét nghiệm -->
            <div class="mb-3">
              <div class="row">
                <div class="col-md-6">
                  <label class="view-label" style="margin-right: 4px;">Họ và tên người bệnh:</label>
                  <span class="fs-16 fw-700 view-value">{{ personData.name }}</span>
                </div>
                <div class="col-md-3">
                  <label class="view-label" style="margin-right: 4px;">Năm sinh:</label>
                  <span class="fs-16 fw-700 view-value">{{ moment(personData.birthday).year() }}</span>
                </div>
                <div class="col-md-3">
                  <label class="view-label" style="margin-right: 4px;">Giới tính:</label>
                  <span class="fs-16 fw-700 view-value">{{ mdtUtils.getGender(personData.gender) }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label class="view-label" style="margin-right: 4px;">Địa chỉ:</label>
                  <span class="fs-16 view-value">{{ personData.address }}</span>
                </div>
              </div>
            </div>
            <!-- End of Thông tin cơ bản của xét nghiệm -->

            <!-- Bảng kết quả xét nghiệm -->
            <el-table
              :data="tableData"
              :span-method="arraySpanMethod"
              border
              style="width: 100%"
              cell-class-name="text-black"
            >
              <el-table-column label="Xét nghiệm" width="180" header-align="center" align="left">
                <template slot-scope="scope">
                  <span :class="scope.row.is_full_row ? 'font-bold' : ''">{{ scope.row.test_name }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Kết quả" header-align="center">
                <template slot-scope="scope">
                  <span class="block text-center" :class="{ 'font-bold': scope.row.is_alert }">
                    <span
                      :style="scope.row.low_high === 'H' ? 'color: red !important' : scope.row.low_high === 'L' ? 'color: blue !important' : ''"
                    >{{ scope.row.result }}</span>
                    <span
                      class="ml-1"
                      :style="scope.row.low_high === 'H' ? 'color: red !important' : scope.row.low_high === 'L' ? 'color: blue !important' : ''"
                      v-if="scope.row.low_high !== ''"
                    >({{ scope.row.low_high }})</span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="unit" label="Đơn vị" header-align="center" align="center"></el-table-column>
              <el-table-column
                prop="normal_rate"
                label="Bình thường"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column prop="device" label="Máy XN" header-align="center" align="center"></el-table-column>
              <el-table-column prop="note" label="Ghi chú" header-align="center" align="left"></el-table-column>
            </el-table>
            <!-- End of Bảng kết quả xét nghiệm -->
          </div>
        </div>
        <div class="flex justify-center" v-if="indicationOrderData.cate === 1">
          <el-button v-if="isLoadingResult" type="primary" :loading="true">Đang tải</el-button>
          <button
            v-else
            @click="handleToggleShowResult"
            type="button"
            class="py-2 px-3 rounded-lg font-medium outline-none focus:outline-none border border-solid btn transition-all duration-200 bg-pri text-white"
          >
            <span v-if="!showResult">
              <span class="align-middle">Xem kết quả</span>
              <i class="fs-16 align-middle el-icon-right ml-2" />
            </span>
            <span v-else>Đóng kết quả</span>
          </button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
::v-deep {
  .hodo-el {
    .el-textarea__inner,
    .el-input__inner {
      background: white !important;
      border: 1px solid #dee2e6 !important;
    }
  }
}

::v-deep .el-table {
  margin-top: 10px;
  border-top: 1px solid #000 !important;

  &__header-wrapper {
    text-transform: uppercase;

    th {
      padding: 8px 0px;
      background-color: #f0f0f0 !important;
    }
  }

  &__row {
    // .el-table__cell:last-of-type {
    //   border-right: none !important;
    // }
  }

  &__cell {
    color: black;
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  &--border {
    border-bottom: 1px solid #000 !important;
  }
}
</style>

<script>
import _ from 'lodash'
import dataDemo from '../../../pages/ElectronicMedicalRecords/components/Indications/ModalIndication/dataMemo'
import appUtils from '../../../utils/appUtils'
import { DOCUMENT_SIGN_TYPES, INDICATION_STATUS } from '../../../utils/constants'
import mdtUtils from '../../../utils/mdtUtils'

export default {
  name: 'CUInficationOrderTemp',
  props: {
    indicationOrderData: {
      type: Object,
      required: true
    },
    type: {
      type: Number,
      required: true
    },
    showIndicationOrderDetail: {
      type: Boolean,
      default: false
    },
    patientVisit: {
      type: Object,
      required: true
    }
  },
  data () {
    const defaultForm = {}

    const generalInfoInitial = {
      diagnose: '',
      sample_taker_name: '',
      take_sample_at: '',
      sample_receiver_name: '',
      received_sample_at: '',
      patient_status: 0
    }

    return {
      demo: dataDemo,
      isLoading: false,
      defaultForm,
      form: defaultForm,
      appUtils,
      mdtUtils,
      debounce: null,
      typing: false,
      indicationOrderDetail: null,
      INDICATION_STATUS,
      showResult: false,
      tableData: [],
      generalInfoInitial,
      generalInfo: generalInfoInitial,
      isLoadingResult: false,
      personData: this.patientVisit?.person || {}
    }
  },
  created () {
    this.indicationOrderDetail = this.indicationOrderData
  },
  mounted () {
    // console.log(this.patientVisit)
  },
  watch: {
    indicationOrderData (data) {
      this.isLoading = true

      if (data) {
        this.indicationOrderDetail = data
      }

      this.isLoading = false
    }
  },
  computed: {
    indicationIds () {
      return this.indicationOrderDetail?.indication?.map(i => i?.id).join(',') || []
    },
    indicationResultDocumentLink () {
      return this.indicationOrderDetail?.document_link || ''
    }
  },
  methods: {
    onHideModal () {
      this.$emit('hideModal', true)
    },
    getIndicationStatus (stt) {
      switch (stt) {
        case INDICATION_STATUS.CODE.PENDING:
          return {
            text: this.$t('Chưa có KQ'),
            class: `text-yellow-600`
          }
        case INDICATION_STATUS.CODE.HAS_RESULT:
          return {
            text: this.$t('Đã có KQ'),
            class: `text-green-700`
          }
        case INDICATION_STATUS.CODE.CS:
          return {
            text: this.$t('Có dấu hiệu bất thường'),
            class: `text-red-600`
          }
        case INDICATION_STATUS.CODE.NCS:
          return {
            text: this.$t('Không có dấu hiệu bất thường'),
            class: `text-blue-800`
          }

        default:
          break
      }
    },
    hasExaminationResults (data) {
      if (data?.examination_result?.id && typeof data?.examination_result !== 'undefined') {
        return {
          text: this.$t('Đã có KQ'),
          class: `text-green-700`
        }
      }
      if (data?.status === 3) {
        return {
          text: this.$t('Đã hủy'),
          class: `text-red-600`
        }
      } else {
        return {
          text: this.$t('Chưa có KQ'),
          class: `text-yellow-600`
        }
      }
    },
    getNameTargetIndication (id) {
      const data = _.find(this.demo, { id })
      return data.name
    },
    onHandleOpenUpdateIndication () {
      this.onHideModal()
      this.$emit('openUpdateIndication', this.indicationOrderDetail)
    },
    goToPrint () {
      this.$router.push({
        name: 'CaseIndicationsPrinter',
        params: { id: this.indicationOrderDetail.id }
      })
    },
    onHandleViewIndicationResult (item) {
      if (this.$route.query?.indication && Number(this.$route.query?.indication) === Number(item?.id)) {
        return
      }

      if (item?.status === 3) {
        this.$message({
          type: 'warning',
          message: this.$t('Xét nghiệm này đã hủy, bạn không thể xem chi tiết.')
        })
        return
      }

      this.$emit('hideModal')

      this.$router.push({
        query: {
          ...this.$route.query,
          indication: item?.id
        }
      })
      this.$emit('onHandleViewIndicationResult', item)
    },
    async handleSignIndicationOrder () {
      const self = this

      if (!self.indicationOrderDetail?.id) {
        self.$message({
          type: 'error',
          message: self.$t('Không tìm thấy thông tin của phiếu chỉ định')
        })
        return
      }

      const _confirm = await self
        .$confirm(self.$t('Phiếu chỉ định khi đã ký sẽ không thể chỉnh sửa. <br />Bạn chắc chắn muốn ký phiếu?'), self.$t('Cảnh báo'), {
          dangerouslyUseHTMLString: true
        })
        .catch(_ => {})

      if (!_confirm) return

      try {
        self.isLoading = true

        const params = {
          id: self.indicationOrderDetail?.id,
          method: 1,
          type: DOCUMENT_SIGN_TYPES.CODE.INDICATION_ORDER
        }

        const res = await self.$rf.getRequest('DoctorRequest').signDocument(params)

        if (res?.data) {
          this.$emit('reloadPage')
          self.isLoading = false
        }
      } catch (error) {
        console.log(error)
        self.isLoading = false
      } finally {
        self.isLoading = false
      }
    },
    getLangCode () {
      return appUtils.getLangCode(appUtils.getLangId(this.$lang))
    },
    getHMDMYVN (date) {
      if (!date) return
      const string = this.moment(date).locale(this.$lang)
      if (this.$lang === this.getLangCode()) {
        const dt = this.moment(date).utc()
        const hm = dt.clone().format('HH:mm')
        const d = dt.clone().format('DD')
        const m = dt.clone().format('MM')
        const y = dt.clone().format('YYYY')
        return hm + ', ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    async handleToggleShowResult () {
      if (this.showResult) {
        this.showResult = !this.showResult
        return
      }

      try {
        const res = await this.handleGetIndicationResult()
        if (!res?.Data?.length) {
          this.$message({
            type: 'warning',
            message: 'Chưa có kết quả phân tích'
          })
          return
        }
        this.showResult = !this.showResult
      } catch (error) {
        console.log(error)
      }
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (row.is_full_row) {
        if (columnIndex === 0) {
          return [1, 6]
        } else return [0, 0]
      }
    },
    async handleGetIndicationResult () {
      this.tableData = []
      this.generalInfo = {
        ...this.generalInfoInitial
      }

      if (!this.indicationIds.length) return

      try {
        this.isLoadingResult = true

        const params = {
          indication_ids: this.indicationIds
        }
        const res = await this.$rf.getRequest('DoctorRequest').getLisIndicationsResultData(params)
        // const res = {
        //   data: {
        //     'Data': [
        //       {
        //         'id': 1042,
        //         'indication_id': 3316,
        //         'name': 'Định lượng Urê máu [Máu]',
        //         'description': '',
        //         'type': 1,
        //         'device': '',
        //         'performer_id': '',
        //         'take_sample_at': null,
        //         'received_sample_at': null,
        //         'result_at': '2024-03-11T22:40:49Z',
        //         'specimen': '',
        //         'html_content': '',
        //         'created_at': '2024-03-13T12:28:29Z',
        //         'updated_at': '2024-03-13T12:28:29Z',
        //         'examination_result_values': [
        //           {
        //             'id': 2622,
        //             'created_at': '2024-03-13T12:28:29Z',
        //             'updated_at': '2024-03-13T12:28:29Z',
        //             'deleted_at': null,
        //             'examination_result_id': 1042,
        //             'indication_id': 0,
        //             'name': 'Định lượng Urê máu [Máu]',
        //             'value': '9',
        //             'unit': 'mmol/l',
        //             'lower': '',
        //             'upper': '',
        //             'is_ncs': 0,
        //             'review_by': 0
        //           }
        //         ]
        //       },
        //       {
        //         'id': 1043,
        //         'indication_id': 3317,
        //         'name': 'Định lượng AFP (Alpha Fetoproteine) [Máu]',
        //         'description': '',
        //         'type': 1,
        //         'device': '',
        //         'performer_id': '',
        //         'take_sample_at': null,
        //         'received_sample_at': null,
        //         'result_at': '2024-03-11T22:40:49Z',
        //         'specimen': '',
        //         'html_content': '',
        //         'created_at': '2024-03-13T12:28:29Z',
        //         'updated_at': '2024-03-13T12:28:29Z',
        //         'examination_result_values': [
        //           {
        //             'id': 2623,
        //             'created_at': '2024-03-13T12:28:29Z',
        //             'updated_at': '2024-03-13T12:28:29Z',
        //             'deleted_at': null,
        //             'examination_result_id': 1043,
        //             'indication_id': 0,
        //             'name': 'Định lượng AFP (Alpha Fetoproteine) [Máu]',
        //             'value': '5',
        //             'unit': 'ng/mL',
        //             'lower': '',
        //             'upper': '',
        //             'is_ncs': 0,
        //             'review_by': 0
        //           }
        //         ]
        //       }
        //     ],
        //     'meta': {
        //       'diagnose': 'Vú không xác định',
        //       'sample_taker_name': '',
        //       'take_sample_at': '',
        //       'sample_receiver_name': '',
        //       'received_sample_at': '',
        //       'sample_status': 0,
        //       'patient_status': 0
        //     }
        //   }
        // }

        if (!res?.data) return

        res.data.Data.forEach(exam_group => {
          this.tableData = [...this.tableData, { test_name: exam_group.name || exam_group.description, is_full_row: true }]
          const resultArr = exam_group?.examination_result_values.map(exam => {
            let lowHigh = Number(exam.lower) !== Number(exam.upper) ? (!isNaN(Number(exam.value)) ? (Number(exam.value) > Number(exam.upper) ? 'H' : Number(exam.value) < Number(exam.lower) ? 'L' : '') : '') : ''

            return {
              test_name: exam.name,
              result: exam.value || '-',
              low_high: lowHigh,
              unit: exam.unit || '-',
              normal_rate: Number(exam.lower) !== Number(exam.upper) ? `${exam.lower} - ${exam.upper}` : '-',
              device: exam_group.device || '-',
              note: exam?.note || '-',
              is_alert: lowHigh !== ''
            }
          })
          this.tableData = [...this.tableData, ...resultArr]
        })
        this.generalInfo =
          {
            ...res.data.meta,
            take_sample_at: appUtils.isDate(res.data.meta?.take_sample_at) ? appUtils.formatDateTimeFull(res.data.meta?.take_sample_at) : '',
            received_sample_at: appUtils.isDate(res.data.meta?.received_sample_at) ? appUtils.formatDateTimeFull(res.data.meta?.received_sample_at) : ''
          } || this.generalInfo

        this.isLoadingResult = false

        return res.data
      } catch (err) {
        console.log(err)
        this.$message({
          message: 'Lấy dữ liệu xét nghiệm không thành công',
          type: 'warning'
        })
      }
    }
  }
}
</script>
