<template>
  <div class="bg-white px-3" v-loading="loading">
    <div class="row">
      <div :class="modifiable ? 'col-12' : 'col-8 offset-2'">
        <div class="d-flex justify-content-between">
          <p class="fs-20 fw-500" v-if="!modifiable">{{ form.name }}</p>
          <div
            class="cursor-pointer"
            v-if="prop_indication_order && !editing"
            @click="editing = true"
          >
            <i class="el-icon-edit fs-24"></i>
          </div>
        </div>
      </div>
      <div :class="modifiable ? 'col-6 relative' : 'col-8 offset-2 relative'">
        <el-input
          class="mb-3"
          placeholder="Tên phiếu chỉ định"
          v-model="form.name"
          :clearable="true"
          v-if="modifiable"
        ></el-input>
        <div class="fs-18 fw-500 txt-grey-900 mb-3">{{$t('schedule_page.lbl_indication_list')}}</div>
        <!-- <div class="text-center my-4">
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="cursor-pointer"
            v-if="!selected || !selected.length"
          >
            <path
              d="M53.3333 23.84C53.3055 23.595 53.2519 23.3537 53.1733 23.12V22.88C53.0451 22.6058 52.8741 22.3538 52.6666 22.1333V22.1333L36.6666 6.13331C36.4462 5.92589 36.1941 5.75486 35.92 5.62665C35.8404 5.61534 35.7596 5.61534 35.68 5.62665C35.4091 5.47129 35.1099 5.37156 34.8 5.33331H18.6666C16.5449 5.33331 14.5101 6.17617 13.0098 7.67646C11.5095 9.17675 10.6666 11.2116 10.6666 13.3333V50.6666C10.6666 52.7884 11.5095 54.8232 13.0098 56.3235C14.5101 57.8238 16.5449 58.6666 18.6666 58.6666H45.3333C47.455 58.6666 49.4899 57.8238 50.9901 56.3235C52.4904 54.8232 53.3333 52.7884 53.3333 50.6666V24C53.3333 24 53.3333 24 53.3333 23.84ZM37.3333 14.4266L44.24 21.3333H40C39.2927 21.3333 38.6144 21.0524 38.1143 20.5523C37.6142 20.0522 37.3333 19.3739 37.3333 18.6666V14.4266ZM48 50.6666C48 51.3739 47.719 52.0522 47.2189 52.5523C46.7188 53.0524 46.0405 53.3333 45.3333 53.3333H18.6666C17.9594 53.3333 17.2811 53.0524 16.781 52.5523C16.2809 52.0522 16 51.3739 16 50.6666V13.3333C16 12.6261 16.2809 11.9478 16.781 11.4477C17.2811 10.9476 17.9594 10.6666 18.6666 10.6666H32V18.6666C32 20.7884 32.8428 22.8232 34.3431 24.3235C35.8434 25.8238 37.8782 26.6666 40 26.6666H48V50.6666ZM37.3333 37.3333H34.6666V34.6666C34.6666 33.9594 34.3857 33.2811 33.8856 32.781C33.3855 32.2809 32.7072 32 32 32C31.2927 32 30.6144 32.2809 30.1143 32.781C29.6142 33.2811 29.3333 33.9594 29.3333 34.6666V37.3333H26.6666C25.9594 37.3333 25.2811 37.6143 24.781 38.1144C24.2809 38.6145 24 39.2927 24 40C24 40.7072 24.2809 41.3855 24.781 41.8856C25.2811 42.3857 25.9594 42.6666 26.6666 42.6666H29.3333V45.3333C29.3333 46.0406 29.6142 46.7188 30.1143 47.2189C30.6144 47.719 31.2927 48 32 48C32.7072 48 33.3855 47.719 33.8856 47.2189C34.3857 46.7188 34.6666 46.0406 34.6666 45.3333V42.6666H37.3333C38.0405 42.6666 38.7188 42.3857 39.2189 41.8856C39.719 41.3855 40 40.7072 40 40C40 39.2927 39.719 38.6145 39.2189 38.1144C38.7188 37.6143 38.0405 37.3333 37.3333 37.3333Z"
              fill="black"
            />
          </svg>

          <div
            class="d-flex align-items-center fs-18 fw-500 txt-grey-900"
            v-else
          >
          <span class="el-icon-circle-plus-outline mr-2"></span>
            Thêm mới
          </div>
        </div>-->
        <div class="selected-indication">
          <div
            class="selected-indication-item"
            :class="modifiable ? 'indication-item-hover' : ''"
            v-for="(indication, i) in selected"
            :key="indication.code + 'idct'"
          >
            <div class="flex align-items-center justify-content-between">
              <div class="w-100">
                <p class="fs-16 fw-500 txt-grey-900 mb-0">{{ indication.name }}</p>
                <div class="row" :class="!modifiable ? 'disable-click' : ''">
                  <div class="col-6">
                    <div class="dropdown">
                      <div
                        class="cursor-pointer el-icon-map-location d-flex h-40px align-items-center"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="max-line-1 ml-1 fs-14 txt-grey-600">
                          {{
                          indication.target_name
                          ? indication.target_name
                          : modifiable
                          ? $t('schedule_page.lbl_execution')
                          : $t('schedule_page.lbl_no_exe_place')
                          }}
                        </span>
                      </div>
                      <div
                        class="dropdown-menu h-80 overflow-y-auto"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          href="javascript:;"
                          v-for="target in demo"
                          :key="target.id + 'target-item'"
                          @click="setNameTargetIndication(indication, target)"
                        >{{ target.name }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="cursor-pointer fs-14 txt-grey-600">
                      <el-date-picker
                        prefix-icon="el-icon-date"
                        v-model="indication.due_date"
                        type="date"
                        :placeholder="
                        modifiable ?  $t('schedule_page.lbl_due_date') : $t('schedule_page.lbl_no_due_date')
                      "
                        class="input-deal-date"
                        :clearable="false"
                      ></el-date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="delete-indication-wraper text-right">
                <span
                  class="delete-indication"
                  @click="deleteIndicationByIndex(i)"
                  v-if="!indication.status"
                >
                  <i class="el-icon-close fs-22"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-end mt-2" v-if="modifiable">
            <button
              class="btn btn-light mr-2"
              @click="handlerCancel"
            >{{$t('schedule_page.btn_cancel')}}</button>
            <button class="btn bg-pri text-white" @click="onSubmit">{{$t('schedule_page.btn_save')}}</button>
          </div>
          <div v-else-if="prop_indication_order" class="d-flex justify-content-end mt-2">
            <button
              class="btn bg-pri text-white"
              @click="goToPrint"
            >{{$t('schedule_page.btn_print')}}</button>
          </div>
        </div>
      </div>
      <div class="col-6 box-shadow pt-2" v-show="modifiable">
        <div
          class="fs-18 fw-500 txt-grey-900 mb-3 max-line-1"
        >{{$t('schedule_page.lbl_add_indicator')}}</div>
        <el-input
          :placeholder="$t('schedule_page.lbl_search_sh')"
          suffix-icon="el-icon-search"
          v-model="search"
          :clearable="true"
          @keyup.enter.native="onSearch(search)"
        ></el-input>
        <div class="flex space-x-3 my-3 select-class pb-1">
          <div
            class="cursor-pointer px-3 py-2 hover:bg-indigo-300 text-black rounded-lg class-item"
            :class="!indicationClassParentId ? 'bg-indigo-200' : ''"
            :key="0 + 'level1'"
            @click="selectIndicationClasses(0)"
          >{{$t('schedule_page.lbl_all')}}</div>
          <div
            class="cursor-pointer px-3 py-2 hover:bg-indigo-300 text-black rounded-lg class-item"
            :class="
              indicationClassParentId === item.id ? 'bg-indigo-200' : 'bg-light'
            "
            v-for="item in indicationClasses"
            :key="item.id + 'indicationClasses'"
            @click="selectIndicationClasses(item.id)"
          >{{ item.name }}</div>
        </div>
        <div class="h-tree select-items">
          <div
            v-infinite-scroll="handleNexPageCategories"
            :infinite-scroll-disabled="disabledScroll"
          >
            <el-tree
              v-loading="indication_class_loading"
              class="filter-tree"
              :data="handledIndicationCategories"
              check-on-click-node
              @check-change="handleCheckIndicationAdd"
              show-checkbox
              ref="tree"
              node-key="code"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span class="fs-16 txt-grey-900 mb-0">
                  {{
                  data.name || node.disabled
                  }}
                </span>
                <br />
                <span class="fs-13 txt-pri" v-html="getCategoryLabel(data.label)"></span>
              </span>
            </el-tree>
            <p
              class="text-center"
              v-if="indication_categories_loading"
            >{{$t('schedule_page.lbl_loading')}}</p>
            <!-- <p class="text-center" v-if="noMore">Đã hết</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import dataDemo from '../../pages/ElectronicMedicalRecords/components/Indications/ModalIndication/dataMemo'
export default {
  name: 'CUInficationOrder',
  props: ['prop_indication_order', 'mr_id', 'hr_id', 'consultation_id'],
  data () {
    return {
      modify: false,
      form: {
        name: ''
      },
      selected: [],
      deleted: [],
      search: '',
      indicationClasses: [],
      indicationClassParentId: null,
      indicationCategories: [],
      indication_class_loading: false,
      indication_categories_loading: false,
      demo: dataDemo,
      moment,
      editing: false,
      loading: false,
      indication_order: null,
      indicationCategoriesPaging: {}
    }
  },
  watch: {
    'indicationCategories.length' (length, old) {
      if (length && old) {
        this.hanldeCheckedBySelected()
      }
    }
  },
  computed: {
    handledIndicationCategories () {
      if (!this.indicationCategories) return []
      let result = this.indicationCategories.map(ig => {
        ig.level = 1
        // ig.label = ig.name
        let foundHasResult = this.indication_order?.indications.find(
          I => I.code === ig.code && I.status
        )
        ig.disabled = !!foundHasResult
        return ig
      })
      // const groups = this.indicationCategories.reduce((groups, Iclass) => {
      //   Iclass.label = Iclass.name
      //   let foundHasResult = this.indication_order?.indications.find(Ic => Ic.code === Iclass.code && Ic.status)
      //   Iclass.disabled = !!foundHasResult
      //   const indication_class_id = Iclass.indication_class_id
      //   if (!groups[indication_class_id]) {
      //     groups[indication_class_id] = []
      //   }
      //   groups[indication_class_id].push(Iclass)
      //   return groups
      // }, {})

      // // Edit: to add it in the array format instead
      // const groupArrays = Object.keys(groups).map((indication_class_id) => {
      //   return {
      //     indication_class_id,
      //     disabled: true,
      //     level: 1,
      //     children: groups[indication_class_id],
      //     label: groups[indication_class_id][0]?.class_name
      //   }
      // })
      return result
    },
    modifiable () {
      return !this.prop_indication_order || !!this.editing
    },
    noMore () {
      return (
        !this.indicationCategoriesPaging ||
        this.indicationCategoriesPaging.current_page >=
          this.indicationCategoriesPaging.last_page
      )
    },
    disabledScroll () {
      return (
        this.indication_class_loading ||
        this.indication_categories_loading ||
        this.noMore
      )
    }
  },
  mounted () {
    this.setDefaultName()
    this.getIndicationClasses()
    this.prepare()
  },
  methods: {
    async prepare () {
      this.selected = []
      this.deleted = []
      const [loadedIOrderDetail, loadedICategories] = await Promise.all([
        this.getIndicationOderDetail(this.prop_indication_order?.id || null),
        this.getIndicationsCategories()
      ])
      if (loadedIOrderDetail && loadedICategories) {
        this.handlerIndicationsOrder()
      }
    },
    onSearch (search) {
      this.search = search
      this.getIndicationsCategories()
    },
    async getIndicationClasses () {
      const params = {
        level: 1,
        all: true
      }
      this.indication_class_loading = true
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getIndicationClasses(params)
      this.indicationClasses = res?.data
      this.indication_class_loading = false
    },
    handleNexPageCategories () {
      if (this.noMore) return
      this.getIndicationsCategories(true)
    },
    async getIndicationsCategories (isNext = false) {
      if (!isNext) this.indicationCategories = []
      const params = {
        search: this.search,
        page: isNext ? this.indicationCategoriesPaging.current_page + 1 : 1,
        level1: this.indicationClassParentId || null,
        limit: 1000
      }
      if (!params.level1) delete params.level1
      if (!params.search) delete params.search
      this.indication_categories_loading = true
      try {
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getIndicationsCategories(params)
        this.indicationCategories = isNext
          ? [...this.indicationCategories, ...res?.data]
          : res?.data
        this.indicationCategoriesPaging = res?.meta
        this.indication_categories_loading = false
        return true
      } catch (error) {
        console.log(error)
        this.indication_categories_loading = false
        return false
      }
    },
    selectIndicationClasses (classId) {
      if (classId) {
        this.indicationClassParentId = classId
      } else {
        this.indicationClassParentId = null
      }
      this.getIndicationsCategories()
    },
    async getIndicationOderDetail (id) {
      if (!id) {
        this.indication_order = null
        return
      }
      this.selected = []
      this.deleted = []
      this.loading = true
      const loaded = await this.$rf
        .getRequest('DoctorRequest')
        .getIndicationOder(id)
        .then(r => {
          this.indication_order = r.data
          return true
        })
        .finally(() => {
          this.loading = false
          return false
        })
      return loaded
    },
    onIndicationAdd (item) {
      if (!item?.children) {
        let newItem = {
          indication_category_id: item.id,
          name: item.name,
          code: item.code,
          target_id: 0,
          target_name: '',
          status: 0
        }
        this.selected.push(newItem)
      }
    },
    setNameTargetIndication (item, t) {
      this.$set(item, 'target_name', t.name)
      this.$set(item, 'target_id', t.id)
      //   this.selected[i].target_name = t.name
      //   this.selected[i].target_id = t.id
    },
    handleCheckIndicationAdd (item, check) {
      if (!item?.children) {
        let foundIndex = this.selected.findIndex(i => i.code === item.code)
        if (foundIndex > -1) {
          if (!check) {
            this.deleteIndicationByIndex(foundIndex)
          }
          return
        }
        let newItem = {
          indication_category_id: item.id,
          name: item.name,
          code: item.code,
          target_id: 0,
          target_name: '',
          due_date: null
        }
        this.selected.push(newItem)
      }
    },
    async ApptStatus (value) {
      const params = {
        status: value
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .ApptStatus(this.appointment.id, params)
      this.getAppts()
    },
    async onSubmit () {
      if (this.prop_indication_order) {
        this.updateIndicationOrder()
        return
      }
      if (!this.form.name) {
        alert('Vui lòng nhập tên phiếu chỉ định.')
        return
      }
      if (!this.selected || !this.selected.length || !this.form.name) {
        alert('Vui lòng chọn chỉ định.')
        return
      }
      const cateIds = this.selected.map(item => {
        delete item.target_name
        return item
      })

      const params = {
        medical_record_id: this.mr_id,
        health_record_id: this.hr_id,
        consultation_id: this.consultation_id,
        name: this.form.name,
        indications: cateIds
      }
      if (!params.medical_record_id) delete params.medical_record_id
      if (!params.health_record_id) delete params.health_record_id
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .postIndicationOder(params)
        .then(res => {
          this.$toast.open({
            message: 'Thêm mới thành công',
            type: 'success'
          })
          this.$emit('refresh', res.data)
          this.handlerCancel()
        })
        .catch(() => {
          this.$toast.open({
            message: 'Thêm mới thất bại',
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateIndicationOrder () {
      if (!this.prop_indication_order) return
      const cateIds = this.selected.map(item => {
        item.remove = false
        delete item.target_name
        return item
      })
      const reomovedIds = this.deleted.map(item => {
        item.remove = true
        return item
      })

      const params = {
        name: this.form.name,
        indications: [...cateIds, ...reomovedIds]
      }
      this.loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .putIndicationOder(this.prop_indication_order.id, params)
        .then(res => {
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
          this.$emit('refresh', res.data)
          this.handlerCancel()
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteIndicationByIndex (foundIndex) {
      if (foundIndex > -1) {
        this.deleted.push(this.selected[foundIndex])
        this.selected.splice(foundIndex, 1)
        this.hanldeCheckedBySelected()
      }
    },
    hanldeCheckedBySelected () {
      try {
        const resultCode = this.selected.map(({ code }) => code) || []
        this.$refs.tree.setCheckedKeys(resultCode)
      } catch (error) {}
    },
    handlerCancel () {
      if (this.prop_indication_order) {
        this.prepare()
        this.editing = false
      } else {
        this.selected = []
        this.hanldeCheckedBySelected()
        this.$emit('refresh')
      }
    },
    setDefaultName () {
      this.form.name =
        this.$t('schedule_page.lbl_indication_sheet') +
        moment().format('DD-MM-YYYY')
    },
    getDataDemo (id) {
      return id ? this.demo[id - 1].name : ''
    },
    handlerIndicationsOrder () {
      this.selected = this.selected.filter(s => !s.id)
      if (this.indication_order?.name) { this.form.name = this.indication_order?.name } else this.setDefaultName()
      if (this.indication_order?.indications) {
        this.indication_order.indications.forEach(item => {
          let newItem = {
            id: item.id,
            indication_category_id: item.indication_category_id,
            name: item.name,
            code: item.code,
            target_id: item.target_id,
            target_name: this.getDataDemo(item.target_id),
            status: item.status,
            due_date: item.due_date
          }
          this.selected.unshift(newItem)
        })
      }
      this.hanldeCheckedBySelected()
    },
    goToPrint () {
      this.$router.push({
        name: 'CaseIndicationsPrinter',
        params: { id: this.prop_indication_order.id }
      })
    },
    getCategoryLabel (string) {
      return string?.replaceAll('-', ' &nbsp; ')
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-dialog__body {
    padding: 16px 10px;
  }
  .el-tree-node__content {
    height: 48px;
  }
  .input-deal-date .el-input__inner {
    border: 0;
    background-color: white !important;
    font-size: 14px;
    font-weight: normal !important;
    color: #667085 !important;
  }
  .input-deal-date .el-input__inner::-webkit-input-placeholder {
    color: #667085; /*Change the placeholder color*/
    opacity: 1; /*Change the opacity between 0 and 1*/
  }
}
.h-40px {
  height: 40px;
}
.disable-click {
  pointer-events: none;
  opacity: 0.7;
}
.select-items {
  height: calc(100vh - 300px);
  overflow-x: hidden;
  overflow-y: auto;
}
.select-class {
  overflow-x: auto;
  width: 100%;
}
.class-item {
  white-space: nowrap;
  flex-grow: 1;
}
.delete-indication-wraper {
  width: 40px;
}
.delete-indication {
  display: none;
  cursor: pointer;
}

.indication-item-hover:hover .delete-indication {
  display: block;
}
.selected-indication {
  padding-right: 8px;
  height: calc(100vh - 250px);
  overflow-y: auto;
}
.selected-indication-item {
  margin-bottom: 16px;
  border-radius: 10px;
  border: 0.5px solid #e6e6e6;
  padding: 8px;
}
.selected-indication-item:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.box-shadow {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}
</style>
