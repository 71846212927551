<template>
  <div>
    <div v-if="breadcrumb">
      <!-- <router-link :to="{path:'/doctor/call-management', params: {type:'upcoming'}}" class="d-flex py-1 align-items-center">
				<img src="../../public/assets/images/icon/pre_blue.svg" alt=""><span class="txt-pri page-title robo-20-500">Hoá đơn</span>
      </router-link>-->
      <Breadcrumb :data="getBreadcrumbData()"></Breadcrumb>
    </div>
    <div class="container">
      <div class="item item1 txt-black">
        <div class="item1-child">
          <div class="display-1399-block">
            <div class="item1-child1 flex space-x-3 py-3">
              <div class="robo-16-500 flex-1">
                Mã đặt lịch
                <div
                  class="txt-blue-04 robo-25-700 mt-2"
                >#{{ invoice_detail.appt && invoice_detail.appt.code }}</div>
              </div>
              <div class="robo-16-500 flex-1">
                Ngày
                <div class="robo-16-400 mt-2">
                  {{
                  invoice_detail.appt.created_at
                  ? formatDate(invoice_detail.appt.created_at)
                  : ""
                  }}
                </div>
              </div>
              <div class="robo-16-500 flex-1">
                Hạn thanh toán
                <div class="robo-16-400 mt-2">
                  {{
                  invoice_detail.appt
                  ? formatDate(invoice_detail.appt.end_time)
                  : ""
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="display-500-none">
            <div class="item1-child1-500">
              <div class="robo-16-500">
                Tình trạng
                <div
                  class="robo-16-500 mt-2"
                  v-if="invoice_detail.status"
                  disabled="true"
                  :style="
                  (invoice_detail.status === 1 || invoice_detail.status === 4)
                    ? 'color: #DE3854;'
                    : invoice_detail.status === 2
                      ? 'color: #FBCF5E;'
                      : 'color: #6CC7A4;'
                "
                >
                  {{
                  (invoice_detail.status === 1 || invoice_detail.status === 4)
                  ? "Chưa thanh toán"
                  : invoice_detail.status === 2
                  ? "Hệ thống chờ xử lý"
                  : "Chấp nhận"
                  }}
                </div>
              </div>
              <div align="right" class="robo-16-500">
                Hạn thanh toán
                <div class="robo-16-400 mt-2">
                  {{
                  invoice_detail.appt
                  ? formatDate(invoice_detail.appt.end_time)
                  : ""
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="item1-child2"></div>
          <div class="item1-child3">
            <div class="item1-child3-child">
              <div>
                <div class="robo-16-500 lineheight-31">Nhà cung cấp dịch vụ</div>
                <div
                  class="robo-16-400 lineheight-31"
                >{{ invoice_detail.clinic ? invoice_detail.clinic.name : "" }}</div>
                <div class="robo-16-400 lineheight-31">
                  {{
                  invoice_detail.clinic ? invoice_detail.clinic.address : ""
                  }}
                </div>
                <div class="robo-16-400 lineheight-31">
                  Hotline:
                  {{ invoice_detail.clinic ? invoice_detail.clinic.phone : "" }}
                </div>
                <div class="robo-16-400 lineheight-31">
                  Email:
                  {{
                  invoice_detail.doctor_id === invoice_detail.doctor_id
                  ? invoice_detail.doctor
                  ? invoice_detail.doctor.user.email
                  : ""
                  : ""
                  }}
                </div>
              </div>
              <div>
                <div class="robo-16-500 lineheight-31">Thông tin khách hàng</div>
                <div class="robo-16-400 lineheight-31">
                  <label>Họ và tên:</label>
                  {{
                  invoice_detail.appt ? invoice_detail.appt.person.name : ""
                  }}
                </div>
                <div class="robo-16-400 lineheight-31">
                  <label>Giới tính:</label>
                  {{
                  invoice_detail.appt
                  ? invoice_detail.appt.person.gender === 1
                  ? "Nam"
                  : invoice_detail.appt.person.gender === 2
                  ? "Nữ"
                  : "Khác"
                  : ""
                  }}
                </div>
                <div class="robo-16-400 lineheight-31">
                  <label>Ngày sinh:</label>
                  {{
                  invoice_detail.appt
                  ? invoice_detail.appt.person.birthday
                  ? formatDate(invoice_detail.appt.person.birthday)
                  : ""
                  : ""
                  }}
                </div>
                <div class="robo-16-400 lineheight-31">
                  <label>Địa chỉ:</label>
                  {{
                  invoice_detail.appt
                  ? invoice_detail.appt.person.address
                  : ""
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="item1-child3">
            <div class="item1-child3-child">
              <div>
                <div class="robo-16-500 lineheight-31">Thông tin dịch vụ</div>
              </div>
              <div class="text-align-right"></div>
            </div>
          </div>
          <div class="item1-child3" style="background-color: #f2f5fa;">
            <div class="item1-child3-child">
              <div>
                <div class="robo-16-500 lineheight-31">Dịch vụ</div>
              </div>
              <div class="text-align-right">
                <div class="robo-16-500 lineheight-31">Thành tiền</div>
              </div>
            </div>
          </div>
          <div class="item1-child4">
            <div class="item1-child3-child">
              <div>
                <div class="robo-16-400 lineheight-31">
                  {{
                  invoice_detail.appt
                  ? invoice_detail.appt.method === 1
                  ? "Dịch vụ tại cơ sở y tế"
                  : "Dịch vụ tư vấn chăm sóc sức khỏe từ xa"
                  : ""
                  }}
                </div>
              </div>
              <div class="text-align-right-default">
                <div
                  class="robo-16-400 lineheight-31 item1-child4-child"
                >{{ formatCurency(invoice_detail.price) }}</div>
                <div class="item1-child3-child">
                  <div class="robo-16-400 lineheight-31 text-align-left-default">Tổng dịch vụ</div>
                  <div class="robo-16-400 lineheight-31">{{ formatCurency(invoice_detail.price) }}</div>
                </div>
                <div class="item1-child3-child item1-child4-child">
                  <div class="robo-16-400 lineheight-31 text-align-left-default">Tài khoản trả trước</div>
                  <div class="robo-16-400 lineheight-31">{{ formatCurency(0) }}</div>
                </div>
                <div class="item1-child3-child">
                  <div
                    class="robo-16-500 lineheight-31 text-align-left-default"
                  >Tổng tiền thanh toán</div>
                  <div class="robo-16-500 lineheight-31">{{ formatCurency(invoice_detail.price) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item item2">
        <div>
          <div class="item2-child1">
            <div style="display: flex; align-items: center">Tình trạng</div>
            <div align="center" class="dropdown">
              <button
                v-if="invoice_detail.status"
                class="btn btn-dropdown-l"
                style="color: #fff; font-weight: 500; width: 100%"
                :style="
                  (invoice_detail.status === 1 || invoice_detail.status === 4)
                    ? 'background-color: #DE3854;'
                    : invoice_detail.status === 2
                      ? 'background-color: #FBCF5E;'
                      : 'background-color: #6CC7A4;'
                "
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="mx-auto">
                  {{
                  (invoice_detail.status === 1 || invoice_detail.status === 4)
                  ? "Chưa thanh toán"
                  : invoice_detail.status === 2
                  ? "Chờ xác nhận"
                  : "Đã thanh toán"
                  }}
                </div>
              </button>
              <div
                v-if="can('approval-appt')"
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="changeInvoicesStatus(3)"
                >Đã thanh toán</a>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="changeInvoicesStatus(1)"
                >Chưa thanh toán</a>
                <!-- <a class="dropdown-item" href="javascript:void(0)" @click="changeInvoicesStatus(2)">Bệnh nhân đã chuyển khoản</a> -->
              </div>
            </div>
          </div>
          <div class="mt-2 item2-child2">
            <div class="robo-16-400 lineheight-31">Số tiền cần thanh toán</div>
            <div
              align="center"
              id="copy-sum"
              class="robo-25-700 lineheight-31"
            >{{ formatCurency(invoice_detail.price) }}</div>
            <div align="right" class="mt-10">
              <span class="icon-copy-white bound-a-copy">
                <a class="text-white wh-20" href="javascript:void(0)" @click="copyText('copy-sum')">
                  Sao chép
                  <img class="wh-20" src="../../public/assets/images/icon/icon_cpoy.svg" />
                </a>
              </span>
            </div>
            <div class="robo-16-400 lineheight-31">Hình thức thanh toán</div>
            <div class="mt-2 mb-2 aglin-txt-center">
              <div class="btn-change-bank" readyonly="true">Chuyển khoản qua ngân hàng</div>
            </div>
            <div class="robo-16-400 lineheight-31">Thông tin ngân hàng</div>
            <div align="center" v-if="my_bank_account[0]">
              <DisplayBankLogo
                :short_name="
                my_bank_account[0]
                  ? splitStrBankName(my_bank_account[0].bank_name)
                  : ''
              "
              ></DisplayBankLogo>
            </div>
            <div class="mt-3 bound-bank">
              <div class="row">
                <div class="col-sm-4 col-12 robo-16-400">Ngân hàng:</div>
                <div
                  class="col-sm-8 col-12 robo-16-500"
                >{{ my_bank_account[0] ? my_bank_account[0].bank_name : "" }}</div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-4 col-12 robo-16-400">Chi nhánh:</div>
                <div
                  class="col-sm-8 col-12 robo-16-500"
                >{{ my_bank_account[0] ? my_bank_account[0].bank_branch : "" }}</div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-4 col-12 robo-16-400">Số tài khoản:</div>
                <div
                  class="col-sm-8 col-12 robo-16-500"
                  id="copy-bank-number"
                >{{ my_bank_account[0] ? my_bank_account[0].bank_number : "" }}</div>
              </div>
              <div class="row" style="margin-top: -9px">
                <div align="right">
                  <span class="icon-copy-white bound-a-copy">
                    <a
                      class="text-white"
                      href="javascript:void(0)"
                      @click="copyText('copy-bank-number')"
                    >
                      Sao chép
                      <img
                        class="wh-20"
                        src="../../public/assets/images/icon/icon_cpoy.svg"
                      />
                    </a>
                  </span>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-4 col-12 robo-16-400">Chủ tài khoản:</div>
                <div class="col-sm-8 col-12 robo-16-500">
                  {{
                  my_bank_account[0]
                  ? my_bank_account[0].bank_account
                  ? my_bank_account[0].bank_account.toUpperCase()
                  : ""
                  : ""
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2 item2-child3">
            <div id="txt-copy" class="row mt-3">
              <div class="col-8" id="copy-link">
                <input class="input-str-copy" type="text" :value="str_url" readonly="true" />
              </div>
              <div class="col-4">
                <button @click="copyText('copy-link')" class="btn-str-copy">Copy</button>
              </div>
            </div>
            <div class="mt-3 aglin-txt-center">
              <button
                class="btn-alert-pay"
                :class="
                invoice_detail.status === 3
                  ? 'bg-pri bd-pri text-white.disabled'
                  : 'btn-backgroup-white'
              "
                :disabled="invoice_detail.status === 3"
                @click="sendNotifyUnpaid()"
              >Nhắc thanh toán</button>
            </div>
            <div align="right" class="mt-2" v-if="invoice_detail.verify_photo">
              <a
                href="javascript:void(0)"
                @click="displayStatement()"
                style="text-decoration: underline"
                class="text-white"
                data-toggle="modal"
                data-target="#exampleModal"
              >Kiểm tra sao kê</a>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div class="modal" tabindex="-1" role="dialog" id="modal-display-statement">
              <div class="modal-dialog" role="document">
                <BankStatements
                  :url_statements="invoice_detail.verify_photo"
                  @showCreate="showCreate"
                ></BankStatements>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="item item3"></div>
    </div>
  </div>
</template>
<script>
import DisplayBankLogo from './DisplayBankLogo.vue'
import BankStatements from './BankStatements.vue'
import Breadcrumb from './Breadcrumb.vue'
import moment from 'moment'
export default {
  name: 'InvoiceDetail',
  props: {
    invoiceId: {
      type: Number
    },
    token: {
      type: String
    },
    breadcrumb: {
      type: Boolean,
      default: true
    }
  },
  components: { DisplayBankLogo, BankStatements, Breadcrumb },
  data () {
    return {
      id_teleme: 0,
      token_teleme: '',
      invoice_detail: Object,
      appt_id: 0,
      appointment_detail: Object,
      my_bank_account: [],
      list_bank_total: 0,
      str_url: '',
      short_name: '',
      is_show_logo: false,
      key_search: '',
      is_show_statements: false,
      user: {},
      clinic: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  mounted () {
    this.user = this.$user
    this.clinic = this.$globalClinic
    this.id_teleme = this.invoiceId || this.$route?.params?.id
    this.token_teleme = this.$route.query.token || this.token
    this.getInvoices(this.id_teleme, this.token_teleme)
    this.str_url =
      process.env.VUE_APP_HODO_WEBSITE +
      '#/invoice/public/' +
      this.id_teleme +
      '?token=' +
      this.token_teleme
  },
  methods: {
    async getInvoices (id, token) {
      try {
        let self = this
        let param = {
          token: self.token_teleme
        }
        let result = await self.$rf
          .getRequest('AuthRequest')
          .getInvoices(self.id_teleme, param)
        self.invoice_detail = result.data
        self.appt_id = result.data.appt_id
        this.getBankAccounts(result.data.clinic_id)
      } catch (e) {
        console.log(e)
      }
    },
    getBreadcrumbData () {
      let data = {}
      const now = moment().unix()
      data = {
        icon: 'appointment.svg',
        pages: [
          {
            text: 'Lịch hẹn làm việc',
            active: false,
            path: `/doctor/appointment-management?date=${now}&type=my`
          },
          {
            text: 'Chi tiết lịch hẹn',
            active: false,
            path: `/doctor/appts/${this.appt_id}`
          },
          { text: 'Hoá đơn', active: true, path: '' }
        ]
      }

      return data
    },
    showCreate (is_show) {
      window.$('#modal-display-statement').modal('hide')
    },
    formatDate (str) {
      return window.moment(str).format('DD/MM/YYYY')
    },
    formatCurency (str) {
      return str
        ? str.toLocaleString('it-IT', { style: 'currency', currency: 'VND' })
        : '0'
    },
    async getBankAccounts (clinicId) {
      if (!clinicId && !this.$globalClinic?.id) return
      try {
        let self = this
        var params = {
          clinic_id: clinicId || this.$globalClinic?.id
        }
        const resultv2 = await self.$rf
          .getRequest('DoctorRequest')
          .getBankAccountsById(params)
        self.list_bank_total = resultv2.data.total
        self.my_bank_account = resultv2.data.data
        if (self.list_bank_total === 0) {
          var r = confirm('Bạn cần có một thông tin tài khoản?')
          if (r) {
            self.$router.push({
              path: '/doctor/clinic',
              query: { type: 'clinic-banks' }
            })
          }
        } else {
          this.short_name = this.splitStrBankName(
            self.my_bank_account[0].bank_name
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    splitStrBankName (name) {
      var shortname = ''
      if (name) {
        if (name.includes('(')) {
          shortname = name
            .split('(')[1]
            .split(')')[0]
            .toLowerCase()
          this.is_show_logo = true
          return shortname
        }
        this.is_show_logo = false
      } else {
        this.is_show_logo = false
      }
    },
    async sendNotifyUnpaid () {
      try {
        let self = this
        await self.$rf
          .getRequest('DoctorRequest')
          .postNotifyUnpaid(self.invoice_detail.id)
        self.$toast.open({
          message: 'Gửi thông báo đến bệnh nhân thành công',
          type: 'success'
        })
        self.sendAmplitude('Send payment remind', {
          'doctor id': self.user.doctor.id,
          'doctor name': self.user.name,
          'workspace id': self.clinic.id,
          'workspace name': self.clinic.name,
          timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
          'request id': self.invoice_detail.id,
          'patient id': self.invoice_detail.appt.person.id,
          'patient name': self.invoice_detail.appt.person.name
        })
      } catch (e) {
        console.log(e)
      }
    },
    async changeInvoicesStatus (type) {
      if (!this.can('approval-appt')) return
      try {
        var r = confirm('Bạn có chắc chắn không?')
        if (r) {
          let self = this
          var param = {
            status: ''
          }
          if (type === 1) {
            param.status = 'reject'
            await self.$rf
              .getRequest('DoctorRequest')
              .postInvoicesStatus(self.invoice_detail.id, param)
          } else if (type === 3) {
            param.status = 'accept'
            await self.$rf
              .getRequest('DoctorRequest')
              .postInvoicesStatus(self.invoice_detail.id, param)
          } else {
            self.$toast.open({
              message: 'Đang nâng cấp chức năng này',
              type: 'info'
            })
          }
          await self.getInvoices(self.id_teleme, self.token_teleme)
          await this.$emit('onRefresh')
        }
      } catch (e) {
        console.log(e)
      }
    },
    copyText (id_element) {
      var code = document.getElementById(id_element)
      const range = document.createRange()
      var selection = window.getSelection()
      selection.removeAllRanges()
      range.selectNodeContents(code)
      selection.addRange(range)
      try {
        if (this.isSupport('copy')) {
          document.execCommand('copy')
          selection.removeAllRanges()
          this.$toast.open({
            message: 'Sao chép thành công',
            type: 'success'
          })
        } else {
          this.$toast.open({
            message: 'Sao chép không được hỗ trợ trên trình duyệt của bạn',
            type: 'error'
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    isSupport (cmd) {
      return document.queryCommandSupported(cmd)
    },
    displayStatement () {
      window.$('#modal-display-statement').modal('show')
    }
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");

.display-flex {
  display: flex;
}

.text-align-right {
  text-align: right;
}

.text-align-right-default {
  text-align: right;
}

.text-align-left-default {
  text-align: left;
}

.container {
  max-width: 1842px;
  margin: 0 auto;
  padding: 5px;
  display: flex;
}

.item {
  margin: 5px;
  color: white;
  width: 50px;
  min-height: 500px;
}

.item1 {
  flex-grow: 2;
  flex-basis: 981px;
  /*background-color: #FFFFFF;
		border-radius: 5px;
		padding: 5px;*/
}

.item1-child {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
}

/* .item1-child1 {
  display: grid;
  grid-template-columns: 44% 28% 28%;
  padding: 15px 20px;
} */

.item1-child2 {
  height: 5px;
  background-color: #f2f5fa;
}

.item1-child3 {
  background-color: #fff;
  padding: 5px 20px;
}

.item1-child4 {
  padding: 5px 20px;
}

.item1-child4-child {
  border-bottom: 2px solid #19181a;
}

.item1-child3-child {
  display: grid;
  grid-template-columns: 50% 50%;
}

.item2 {
  flex-grow: 1;
  flex-basis: 421px;
}

.item2-child1 {
  padding: 15px 20px;
  background-color: #20409b;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: grid;
  grid-template-columns: 30% 70%;
}

.item2-child2 {
  padding: 15px 20px;
  background-color: #20409b;
}

.item2-child3 {
  padding: 15px 20px;
  background-color: #20409b;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.item3 {
  flex-shrink: 2;
}

.robo-25-700 {
  font-weight: 700;
  font-size: 25px;
}

.txt-black {
  color: #fff;
}

.txt-medium-grey {
  color: #6f6e70;
}

.txt-blue-04 {
  color: #20419b;
}

.lineheight-31 {
  line-height: 31.35px;
}

.display-block {
  display: block;
}

.table-bank-tr-frist {
  min-width: 110px;
  display: flex;
  justify-content: top;
}

.font-weight-bold {
  font-weight: 500;
  font-size: 1vw;
}

.btn-change-status {
  color: #fff;
  width: 100%;
  font-weight: bold;
  background-color: #20409b;
  padding: 10px 10px;
  text-align: center;
  border: none;
  border-radius: 5px;
}

.btn-change-status:focus {
  outline: none;
}

.txt-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
}

.txt-font-size-16 {
  display: block;
  font-size: 0.9vw;
}

.txt-fz-16 {
  font-style: 16px;
}

.txt-white {
  color: #fff;
}

.icon-copy-white:before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
  font-size: 8px;
  color: #fff;
}

.min-height-98 {
  min-height: 98vh;
}

.bound-big-big {
  background-color: #ffffff;
  border-radius: 8px;
  border-bottom: 0.5px solid ragb(0, 0, 0, 0.5);
  min-height: 100%;
}

.bound-big {
  background-color: #ffffff;
  border-radius: 8px;
  border-bottom: 0.5px solid ragb(0, 0, 0, 0.5);
  padding: 15px 20px;
}

.bound-small {
  background-color: #20409b;
  border-radius: 8px;
  height: 100%;
  margin-right: 20px;
}

.btn-status {
  height: 30px;
}

.algin-center {
  display: flex;
  align-items: center;
}

.font-size-12 {
  font-size: 12px;
}

.mtop-15 {
  margin-top: 15px;
}

.background-color-gray {
  background-color: #f8f7fa;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.p-10-10 {
  padding: 10px 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.bb-hr {
  border-bottom: 3px solid black;
}

.txt-black {
  color: #19181a;
}

.p-15-5 {
  padding: 15px 5px;
}

.p-15-8 {
  padding: 15px 16px;
}

.aglin-txt-center {
  display: flex;
  justify-content: center;
}

.mt-10 {
  margin-top: -10px;
}

.wh-20 {
  width: 20px;
  height: 20px;
}

.btn-change-bank {
  border-radius: 50px;
  color: #20409b;
  width: 100%;
  cursor: default !important;
  background-color: #fff;
  padding: 12px 20px;
  text-align: center;
  font-weight: bold;
}

.a-edit {
  font-size: 10px;
  font-weight: normal;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.bound-bank {
  width: 100%;
  font-weight: normal;
  font-size: 13px;
}

.lh-30 {
  line-height: 30px;
}

.bound-a-copy {
  font-size: 10px;
  color: #fff;
}

.input-str-copy {
  color: #20409b;
  font-size: 11px;
  width: 100%;
  height: 30px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
}

.btn-str-copy {
  font-size: 10px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: none;
  color: #20409b;
  font-weight: bold;
  background-color: #fff;
}

.btn-alert-pay {
  width: 100%;
  font-weight: bold;
  padding: 12px 20px;
  text-align: center;
  border: none;
  border-radius: 5px;
}

.btn-backgroup-white {
  color: #20409b;
  background-color: #fff;
}

.btn-dropdown-l:after {
  width: 100% !important;
}

.bound-big {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 3;
  overflow-y: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}

.display-500-none {
  display: none;
}

.display-1399-block {
  display: block;
}

.item1-child1-500 {
  display: grid;
  padding: 15px 20px;
  grid-template-columns: 50% 50%;
}

/* @media (max-width: 1200px) AND (min-width: 1000px) {
  .item1-child1 {
    display: grid;
    grid-template-columns: 34% 36% 20%;
    padding: 15px 20px;
  }
} */

@media (max-width: 1000px) AND (min-width: 500px) {
  .container {
    max-width: 1250px;
    margin: 0 auto;
    padding: 5px;
    display: inline-flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 1000px) {
  .item3 {
    display: none;
  }
}

@media (max-width: 500px) {
  .container {
    max-width: 1250px;
    margin: 0 auto;
    padding: 5px;
    display: inline-flex;
    flex-wrap: wrap;
  }

  /* .item1-child1 {
    display: grid;
    grid-template-columns: 1fr;
    padding: 15px 20px;
  } */

  .item1-child3-child {
    display: grid;
    grid-template-columns: 1fr;
  }

  .item3 {
    display: none;
  }

  .text-align-right {
    text-align: left;
  }

  .display-500-none {
    display: block;
  }

  .display-1399-block {
    display: none;
  }
}
</style>
