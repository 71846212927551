<template>
  <el-dialog
    :title="$t('Danh sách Phiếu thu')"
    :visible.sync="isOpen"
    :modal="false"
    fullscreen
    center
    @close="handleClose"
  >
    <div v-loading="isLoading">
      <el-table
        @row-click="handleViewDetail"
        :data="tableData"
        style="width: 100%"
        class="cursor-pointer"
      >
        <el-table-column prop="number_code" :label="$t('Số phiếu thu')">
        </el-table-column>
        <el-table-column prop="person_name" :label="$t('Tên bệnh nhân')">
        </el-table-column>
        <el-table-column prop="created_by.name" :label="$t('Người lập')">
        </el-table-column>
        <el-table-column
          prop="create_at"
          :formatter="(row) => appUtils.formatDateTime(row.created_at)"
          :label="$t('Ngày lập')"
        >
        </el-table-column>
      </el-table>
      <AppPagination
        :paginationProps="pagination"
        @onChangePagination="getListIndicationReceipt"
      />
    </div>
  </el-dialog>
</template>

<script>
import appUtils from '../../../utils/appUtils'
import AppPagination from '../../Common/AppPagination.vue'
export default {
  name: 'ModalIndicationReceipt',
  components: { AppPagination },
  props: {
    indicationResponse: Object,
    ctaTrigger: Number
  },
  data () {
    return {
      isOpen: false,
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        totalItems: 0
      },
      isLoading: false,
      appUtils
    }
  },
  watch: {
    indicationResponse: {
      deep: true,
      handler () {
        this.getListIndicationReceipt()
      }
    },
    ctaTrigger () {
      this.getListIndicationReceipt()
    }
  },
  created () {
    this.getListIndicationReceipt()
  },
  methods: {
    handleOpen () {
      this.isOpen = true
    },
    async getListIndicationReceipt (data) {
      if (!this.indicationResponse?.id) return
      try {
        const params = {
          indication_order_id: this.indicationResponse?.id,
          order: 'desc',
          sort_by: 'created_at',
          page_num: data?.currentPage || this.pagination.currentPage,
          page_size: data?.pageSize || this.pagination.pageSize
        }

        this.isLoading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListIndicationReceipt(params)
        this.tableData = response.data?.data || []
        this.pagination = {
          currentPage: response.data?.page?.page_num,
          totalItems: response.data?.page?.total,
          pageSize: response.data?.page?.page_size
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleViewDetail (row) {
      this.isOpen = false
      this.$router.push({
        name: 'IndicationReceiptDetail',
        params: {
          id: row.id
        }
      })
    },
    handleClose () {
      if (this.ctaTrigger) {
        this.$emit('onClose')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>